import { useQuery } from "@tanstack/react-query"
import { getRemainingCopies } from "api/core"

export const useGetRemainingCopies = () => {
  return useQuery({
    queryKey: ["remaining-copies"],
    queryFn: getRemainingCopies,
    refetchOnWindowFocus: false,
    retry: 1,
  })
}
