import CountUp from "react-countup"
import { cx } from "styles"

import styles from "./Loading.module.scss"

const PERCENT_START = 1
const PERCENT_END = 100

const ANIMATION_DURATION = 3.967 // second
const ANIMATION_DELAY = 0.15 // second

const TITLE = "Kodai"

interface LoadingProps {
  className?: string
}

export const Loading = ({ className }: LoadingProps) => {
  return (
    <div className={cx(styles.root, className)}>
      <div className={cx(styles.titleContainer)}>
        <div className={styles.title}>{formattedTitle}</div>
      </div>
      <CountUp
        className={styles.percentage}
        delay={ANIMATION_DELAY}
        duration={ANIMATION_DURATION}
        end={PERCENT_END}
        startVal={PERCENT_START}
        suffix="%"
      />
    </div>
  )
}

const formattedTitle = TITLE.split("").map((letter, index) => {
  const trimmedLetter = letter.trim()

  return (
    <span
      className={cx(
        styles.letters,
        trimmedLetter,
        // for delay
        styles[`letters-${index + TITLE.length}`],
        {
          [styles.empty]: !trimmedLetter,
        },
      )}
      key={trimmedLetter + index}>
      {trimmedLetter}
    </span>
  )
})
