import { object, email, ERROR_TEXT, persistedStore } from "lib"

export const validationSchema = object().shape({
  email: email().required(ERROR_TEXT.requiredEmail),
})

export const cardStyles = {
  fontSize: "13px",
  fontWeight: 400,
  fontFamily: "Open Sans, sans-serif",
  color: "#fff",
  ":-webkit-autofill": {
    color: "#fff",
  },
  "::placeholder": {
    fontSize: "13px",
    color: "rgba(255, 255, 255, 0.32)",
    fontWeight: 400,
  },
}

// the loading animation takes 4 seconds (src/modules/Purchase/PaymentPage/PaymentPage.module.scss)
const ADDITIONAL_TIME = 4 // seconds

export const storeTime = (seconds: number | null) => {
  if (seconds && Number(seconds) > 0) {
    persistedStore.setItem({
      data: seconds + ADDITIONAL_TIME || 0 + ADDITIONAL_TIME,
      key: "time",
    })
  } else {
    persistedStore.clearItem("time")
  }
}
