import { ReactNode } from "react"
import { cx } from "styles"

import styles from "./InfoBar.module.scss"

interface CustomCSSProperties extends React.CSSProperties {
  "--borderGradient"?: string
  "--backgroundImage"?: string
}

interface InfoBarProps {
  className?: string
  leftContent: {
    text: ReactNode
    imageSrc: string
  }
  rightContent?: ReactNode
  borderGradient?: string
  backgroundImage?: string
}

export const InfoBar = ({
  className,
  leftContent,
  rightContent,
  borderGradient,
  backgroundImage,
}: InfoBarProps) => {
  const style: CustomCSSProperties = {
    "--borderGradient": borderGradient || "transparent",
    "--backgroundImage": backgroundImage || "transparent",
  }

  return (
    <div className={cx(styles.root, className)} style={style}>
      <div className={cx(styles.contentContainer)}>
        <div className={cx(styles.content)}>
          <div className={styles.text}>
            <img
              alt="logo"
              className={styles.icon}
              draggable={false}
              src={leftContent.imageSrc}
            />
            <span>{leftContent.text}</span>
          </div>

          {!!rightContent && (
            <div className={styles.rightContent}>{rightContent}</div>
          )}
        </div>
      </div>
    </div>
  )
}
