import { CSSProperties, useCallback, useRef, useState } from "react"

export const useLayoutStyles = () => {
  const headerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [headerStyles, setHeaderStyles] = useState<CSSProperties | {}>({})
  const [contentStyles, setContentStyles] = useState<CSSProperties | {}>({})

  const calculateStyles = () => {
    if (headerRef.current && contentRef.current) {
      const headerHeight = headerRef.current.clientHeight
      const contentPaddingTop = headerHeight + "px"

      setHeaderStyles({ position: "absolute" })
      setContentStyles({ paddingTop: contentPaddingTop })
    }
  }

  const resetStyles = useCallback(() => {
    setHeaderStyles({ position: "relative" })
    setContentStyles({ paddingTop: 0 })
  }, [])

  return {
    headerRef,
    contentRef,
    headerStyles,
    contentStyles,
    calculateStyles,
    resetStyles,
  }
}
