import { video } from "assets/video"
import { useMediaQuery } from "hooks"
import { cx } from "styles"

import styles from "./AvailabilityLoading.module.scss"

interface AvailabilityLoadingProps {
  className?: string
  loading: boolean
  onAnimationEnd: () => void
}

export const AvailabilityLoading = ({
  className,
  onAnimationEnd,
  loading,
}: AvailabilityLoadingProps) => {
  const isMobile = useMediaQuery("mobile")

  if (!loading) {
    return <></>
  }

  return (
    <div className={cx(styles.root, className)}>
      <video autoPlay muted playsInline onEnded={onAnimationEnd}>
        <source
          src={
            isMobile ? video.checkingAnimationMobile : video.checkingAnimation
          }
          type="video/mp4"
        />
        Your browser does not support the video tag
      </video>
    </div>
  )
}
