import { countries } from "../../sections/Regions/countries"
import { supportedRegions } from "../../sections/Regions/regionsList"

import { filterByCountryCode } from "./utils"

const N = 8

export const arcsGlobeData = Array.from({ length: N }, () => ({
  startLat: (Math.random() - 0.5) * 180,
  startLng: (Math.random() - 0.5) * 360,
  endLat: (Math.random() - 0.5) * 180,
  endLng: (Math.random() - 0.5) * 360,
  color: "#5c6293",
}))

export const positionsFlagData = filterByCountryCode(
  countries,
  supportedRegions,
)

export const pointFlagData = filterByCountryCode(countries, supportedRegions)

export const globeSettings = {
  pointLat: "latitude",
  pointLng: "longitude",
  pointRadius: 1.5,
  htmlLat: "latitude",
  htmlLng: "longitude",
  arcColor: "color",
  pointColor: "color",
}
