import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { STRIPE_KEY } from "api/core"
import { useGetRemainingCopies } from "api/hooks/useGetRemainingCopies"
import { Spinner } from "components"
import { useGenericLink, usePageUnload, useTimer } from "hooks"
import { cx } from "styles"

import { PaymentLayout } from "../../components"

import { PRICE, PaymentCardForm } from "./Form"
import { storeTime } from "./Form/utils"
import styles from "./PaymentForm.module.scss"

interface PaymentFormProps {
  className?: string
  minimizedMobileHeader: boolean
  isPurchaseSuccess: boolean
  initialTime: number | null
  onMaximize: () => void
  onMinimize: () => void
  onAvailable: () => void
}

const stripePromise = loadStripe(STRIPE_KEY)

const stripeElementsOptions = {
  fonts: [
    {
      // as similar as possible to the font used in the project
      // local fonts cannot be used
      cssSrc:
        "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap",
      fontFamily: `Inter, sans-serif`,
      style: "normal",
      weight: "400",
    },
  ],
}

export const PaymentForm = ({
  className,
  onAvailable,
  minimizedMobileHeader,
  onMaximize,
  onMinimize,
  initialTime,
  isPurchaseSuccess,
}: PaymentFormProps) => {
  const { isLoading, error, data } = useGetRemainingCopies()
  const termsLinkProps = useGenericLink("https://hub.kodai.io/tos")

  const { expiredTime } = useTimer({
    initialTime,
    onAvailable,
    isPurchaseSuccess,
  })

  usePageUnload(() => storeTime(expiredTime))

  const copies = error ? 0 : data?.available

  return (
    <PaymentLayout
      className={className}
      expiredTime={expiredTime}
      footerText={`*Kodai currently costs $${PRICE} initially and then $49.99 after the first month.`}
      minimizedMobileHeader={minimizedMobileHeader}
      title={{
        firstPart: "Get access to Kodai for only",
        secondPart: "*",
      }}
      titleUnderlined={`$${PRICE}`}
      onMaximize={onMaximize}
      onMinimize={onMinimize}>
      <div className={styles.content}>
        <Elements options={stripeElementsOptions} stripe={stripePromise}>
          <PaymentCardForm className={styles.form} />
        </Elements>

        <div
          className={cx(styles.info, {
            [styles.infoLoading]: isLoading || (!data && !error),
          })}>
          <Spinner className={styles.copiesLoading} size={16} />
          <div className={cx(styles.infoText)}>
            You better be quick... only{" "}
            <span className={styles.amount}>
              {copies} {copies === 1 ? "copy" : "copies"}
            </span>{" "}
            {copies === 1 ? "is" : "are"} left!
          </div>
        </div>
        <div className={styles.disclaimer}>
          By completing this transaction you agree to our{" "}
          <span className={styles.termsLink} {...termsLinkProps}>
            Terms of Service
          </span>
          .
        </div>
      </div>
    </PaymentLayout>
  )
}
