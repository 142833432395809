import cx from "classnames"
import isNumber from "lodash-es/isNumber"

import styles from "./Spinner.module.scss"

const sizes = {
  large: 44,
  medium: 21,
  small: 18,
}

interface SpinnerProps {
  size?: "large" | "medium" | "small" | number
  className?: string
  color?: string
}

export const Spinner = ({
  className,
  size = "medium",
  color = "#fff",
}: SpinnerProps) => {
  const spinnerSize = isNumber(size) ? size : sizes[size]

  return (
    <div
      className={cx(styles.root, className)}
      style={{ color, width: spinnerSize, height: spinnerSize }}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
