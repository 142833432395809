import { icons } from "assets/icons"
import { Button } from "components"
import { ERROR_TEXT } from "lib"
import { purchaseErrorMessage } from "lib/purchaseErrorMessage"
import { cx } from "styles"

import styles from "./PaymentError.module.scss"

interface PaymentErrorProps {
  onRetry: () => void
  error: string | null
}

export const PaymentError = ({ onRetry, error }: PaymentErrorProps) => {
  return (
    <div className={cx(styles.root, { [styles.shown]: !!error })}>
      <div className={styles.card}>
        <div className={styles.header}>error.exe</div>
        <div className={styles.content}>
          <img
            alt="error"
            className={styles.icon}
            draggable={false}
            src={icons.error}
          />
          <div className={styles.title}>Payment Failed</div>
          <div className={styles.description}>
            Oh no! Your payment was not able to be completed. <br />
            <span className={styles.error}>
              {error ? purchaseErrorMessage(error) : ERROR_TEXT.default}
            </span>
          </div>
          <Button className={styles.button} onClick={onRetry}>
            Retry
          </Button>
        </div>
      </div>
    </div>
  )
}
