import { images } from "assets/images"
import isNull from "lodash-es/isNull"
import { Fragment, useEffect, useRef, useState } from "react"
import { cx } from "styles"
import { NavigationTab } from "types/tabs"

import styles from "./Tabs.module.scss"
import { VideoPlayer } from "./VideoPlayer"
import { tabsList } from "./tabsList"

interface TabsProps {
  className?: string
  activeTab: NavigationTab
  onTabChange: (tab: NavigationTab) => void
}

const navigationTabs = Object.values(NavigationTab)

export const Tabs = ({ className, activeTab, onTabChange }: TabsProps) => {
  const [hoveredTab, setHoveredTab] = useState<NavigationTab | null>(null)
  const [activeTabWidth, setActiveTabWidth] = useState(0)
  const [activeTabLeft, setActiveTabLeft] = useState(0)
  // to move the dot under the tabs
  const tabsRef = useRef<{ [key in NavigationTab]?: HTMLElement }>({})
  // autoplay animation
  const videoRef = useRef<{ [key in NavigationTab]?: HTMLVideoElement }>({})

  useEffect(() => {
    const onChangePosition = () => {
      const currentTab = tabsRef.current[activeTab]
      setActiveTabLeft(currentTab?.offsetLeft ?? 0)
      setActiveTabWidth(currentTab?.clientWidth ?? 0)
      const currentVideo = videoRef.current[activeTab]

      if (videoRef.current && currentVideo) {
        navigationTabs.forEach((tab) => {
          if (activeTab === tab) {
            videoRef.current[activeTab]?.play()
          } else {
            if (videoRef.current[tab]?.played) {
              videoRef.current[tab]?.pause()
            }
          }
        })
      }
    }

    onChangePosition()
    window.addEventListener("resize", onChangePosition)

    return () => {
      window.removeEventListener("resize", onChangePosition)
    }
  }, [activeTab])

  const activeIconWidth = 30
  // centring
  const leftPosition = activeTabLeft + activeTabWidth / 2 - activeIconWidth / 2

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.container}>
        <div className={styles.content}>
          {navigationTabs.map((tab) => {
            return (
              <Fragment key={tab}>
                <div
                  className={styles.tabContainer}
                  ref={(el) => {
                    if (!el) return
                    tabsRef.current[tab] = el
                  }}
                  onMouseEnter={() => setHoveredTab(tab)}
                  onMouseLeave={() => setHoveredTab(null)}>
                  <button
                    className={cx(styles.tab, {
                      [styles.hovered]:
                        !isNull(hoveredTab) && tab === hoveredTab,
                    })}
                    onClick={() => onTabChange(tab)}>
                    <div className={styles.tabTitle}>{tabsList[tab].title}</div>
                    <div className={styles.tabContent}>
                      <VideoPlayer
                        activeTab={activeTab}
                        className={styles.tabVideo}
                        hoveredTab={hoveredTab}
                        tab={tab}
                        videoRef={videoRef}
                      />
                    </div>
                  </button>
                </div>
              </Fragment>
            )
          })}

          <div
            className={styles.movedIcon}
            style={{
              left: leftPosition,
            }}>
            <img
              className={cx(styles.iconBright)}
              draggable={false}
              src={images.tabEllipseBrighter}
            />
            <img
              className={styles.icon}
              draggable={false}
              src={images.tabEllipse}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
