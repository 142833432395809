import AT from "./at.svg"
import AU from "./au.svg"
import BE from "./be.svg"
import BG from "./bg.svg"
import BR from "./br.svg"
import CA from "./ca.svg"
import CH from "./ch.svg"
import CN from "./cn.svg"
import CZ from "./cz.svg"
import DE from "./de.svg"
import DK from "./dk.svg"
import ES from "./es.svg"
import FI from "./fi.svg"
import FR from "./fr.svg"
import GR from "./gr.svg"
import HK from "./hk.svg"
import HU from "./hu.svg"
import ID from "./id.svg"
import IE from "./ie.svg"
import IL from "./il.svg"
import IN from "./in.svg"
import IT from "./it.svg"
import JP from "./jp.svg"
import KR from "./kr.svg"
import LT from "./lt.svg"
import LU from "./lu.svg"
import MO from "./mo.svg"
import MX from "./mx.svg"
import MY from "./my.svg"
import NL from "./nl.svg"
import NO from "./no.svg"
import NZ from "./nz.svg"
import PH from "./ph.svg"
import PL from "./pl.svg"
import PT from "./pt.svg"
import RO from "./ro.svg"
import RU from "./ru.svg"
import SE from "./se.svg"
import SG from "./sg.svg"
import SI from "./si.svg"
import SK from "./sk.svg"
import TH from "./th.svg"
import TR from "./tr.svg"
import TW from "./tw.svg"
import UK from "./uk.svg"
import US from "./usa.svg"
import VN from "./vn.svg"

export const flags = {
  AU,
  AT,
  BE,
  BG,
  BR,
  CA,
  CH,
  CN,
  CZ,
  DE,
  DK,
  ES,
  FI,
  FR,
  GR,
  HK,
  HU,
  ID,
  IE,
  IL,
  IN,
  IT,
  JP,
  KR,
  LT,
  LU,
  MO,
  MX,
  MY,
  NL,
  NO,
  NZ,
  PH,
  PL,
  PT,
  RO,
  RU,
  SE,
  SG,
  SI,
  SK,
  TH,
  TR,
  TW,
  UK,
  US,
  VN,
}
