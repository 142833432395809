import { usePurchaseSubscription } from "api/hooks/usePurchaseSubscription"
import { ReactNode, createContext } from "react"
import { PurchaseInfo } from "types/purchase"

interface Values {
  isLoading: boolean
  isSuccess: boolean
  error: string | null
  mutateAsync: (data: PurchaseInfo) => Promise<void>
  reset: () => void
}

export const PurchaseContext = createContext<Values | null>(null)

interface PurchaseProviderProps {
  children: ReactNode
}
export const PurchaseProvider = ({ children }: PurchaseProviderProps) => {
  const { mutateAsync, isLoading, error, isSuccess, reset } =
    usePurchaseSubscription()

  return (
    <PurchaseContext.Provider
      value={{
        mutateAsync,
        isLoading,
        error: error ? (error as Error).name : null,
        isSuccess,
        reset,
      }}>
      {children}
    </PurchaseContext.Provider>
  )
}
