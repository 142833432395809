import { useEffect, useState } from "react"

import variables from "../styles/base/responsive.scss"

export const useMediaQuery = (
  device: keyof typeof variables,
  type?: "min" | "max",
) => {
  const mediaType = !type ? "max" : type
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia(
      `(${mediaType}-width: ${variables[device]})`,
    )

    const handleChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches)
    }

    mediaQuery.addEventListener("change", handleChange)
    setMatches(mediaQuery.matches)

    return () => {
      mediaQuery.removeEventListener("change", handleChange)
    }
  }, [device, mediaType])

  return matches
}
