const baseDurationMs = 5000
// error display probability
const throwPercentage = 20

export const withFakeServer = async <T>(f: () => T, delayMs?: number) => {
  const delayNum = delayMs ? delayMs : baseDurationMs
  await delay(delayNum + Math.random() * 500)
  maybeThrow()
  await delay(delayNum + Math.random() * 500)
  return f()
}

const maybeThrow = () => {
  const random = Math.floor(Math.random() * 100)
  if (throwPercentage <= random) return
  throw new Error("API broke")
}

export const delay = async (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const makeApiError = (name: string, message: string) => {
  const error = new Error(message)
  error.name = name
  return error
}
