import { Country, CountryPoint } from "types/tabs"

export const filterByCountryCode = (arr: Country[], codes: string[]) => {
  const result: CountryPoint[] = []
  const POINT_COLOR = "transparent"
  codes.forEach((code) => {
    const filtered = arr.find(
      (obj) => obj.key.toLowerCase() === code.toLowerCase(),
    )
    if (filtered) {
      result.push({ ...filtered, color: POINT_COLOR })
    }
  })
  return result
}

export const tooltipStyles = `
font-size: 1.4rem;
text-align: left;
background: green;
padding: 0.6rem;
border-radius: 0.3rem;
background-color: #191B2E;
border: 1px solid #4c4d5b;
position: relative;
z-index: 9999;
`

export const getTooltip = (place: CountryPoint) => {
  return `
  <div style="${tooltipStyles}">
    <div>${place.name}</div>
   </div>
`
}
