import { images } from "assets/images"
import { getObjectKeys } from "utils/getObjectKeys"

import { FeatureType } from "./components/Features"

export type SiteType =
  | "amazon"
  | "shopify"
  | "footlocker"
  | "asos"
  | "jd_finishline"
  | "rakuten"
  | "nike"
  | "zalando"
  | "target"
  | "adidas"

export const SITES: Record<
  SiteType,
  { imagePath: string; features: FeatureType[] }
> = {
  amazon: {
    imagePath: images.siteAmazon,
    features: [
      {
        title: "Freebies",
        features: ["Run 24/7 for FREE or heavily discounted products!"],
      },
      {
        title: "Advanced Account Support",
        features: [
          "Effortlessly login to your accounts once and never worry about them again.",
        ],
      },
      {
        title: "20+ Regions Supported",
        features: [
          "With support for over 20 different regions, no Amazon product is out of your each!",
        ],
      },
    ],
  },
  asos: {
    imagePath: images.siteAsos,
    features: [
      {
        title: "Blazing Fast",
        features: [
          "With blazing fast checkout speeds and techniques, nothing stands a chance against Kodai.",
        ],
      },
      {
        title: "Quantity + Discounts",
        features: [
          "Specify an item quantity or discount code you wish to use during checkout and let Kodai handle the rest!",
        ],
      },
      {
        title: "Automatic Captcha Solving",
        features: [
          "Skip the hassles of captchas when logging in. ZERO captchas are required with Kodai!",
        ],
      },
    ],
  },
  zalando: {
    imagePath: images.siteZalando,
    features: [
      {
        title: "Effortless Setup",
        features: [
          'Setting up Zalando with Kodai is effortless. NO "COOKIES" or any manual labor is required. Simply create your tasks and START COOKING!',
        ],
      },
      {
        title: "Optimized Monitoring",
        features: [
          "Monitor for product restocks 24/7 and INSTANTLY checkout when a product you desire is back in-stock.",
        ],
      },
      {
        title: "PLUS Tools",
        features: [
          "Automatically subscribe or unsubscribe accounts from Zalanado PLUS.",
        ],
      },
    ],
  },
  jd_finishline: {
    imagePath: images.siteFinishLine,
    features: [
      {
        title: "Desktop + Mobile Support",
        features: [
          "Choose to checkout through their website or mobile application to maximize your chances of success.",
        ],
      },
      {
        title: "Crazy Efficient",
        features: [
          "Run THOUSANDS of tasks at once and INSTANTLY checkout any in-stock product you desire.",
        ],
      },
      {
        title: "Account Support",
        features: [
          "Optionally login to an account when checking out to gain rewards points and early access drops.",
        ],
      },
    ],
  },
  shopify: {
    imagePath: images.siteShopify,
    features: [
      {
        title: "Automations + SKU Support",
        features: [
          "Never miss a restock! Specify a list of products OR product SKUs and wait for restocks 24/7 on OVER 100+ Shopify sites.",
        ],
      },
      {
        title: "AI Captcha Solving",
        features: [
          "Stop solving captchas. Kodai utilizes AI to automatically solve ANY captcha during checkout for you!",
        ],
      },
      {
        title: "Fast & Safe Mode",
        features: [
          "Checkout in LESS THAN 1 second with fast mode or go undetected on ANY anti-bot enabled release with safe mode.",
        ],
      },
    ],
  },
  footlocker: {
    imagePath: images.siteFootlocker,
    features: [
      {
        title: "ALL Regions Supported",
        features: [
          "Kodai supports Footlocker from EVERY SINGLE REGION! Some notable regions (but not all) are: Australia, Europe, Singapore and the United States.",
        ],
      },
      {
        title: "FLX Support",
        features: [
          'Easily enter TENS OF THOUSANDS of accounts and increase your win-rate by automatically applying FLX "head-starts".',
        ],
      },
      {
        title: "24/7 Monitoring",
        features: [
          "Monitor a list of products 24/7 and checkout INSTANTLY when each product restocks!",
        ],
      },
    ],
  },
  rakuten: {
    imagePath: images.siteRakuten,
    features: [
      {
        title: "Captcha Support",
        features: [
          "Dominate any Rakuten release! Captchas for HYPE releases are fully supported with Kodai.",
        ],
      },
      {
        title: "BOOKS Support",
        features: [
          "Rakuten BOOKS is specifically supported and optimized for Kodai to ensure maximum user success!",
        ],
      },
      {
        title: "Advanced Account Support",
        features: [
          "Login to your accounts once and never worry about them again!",
        ],
      },
    ],
  },
  nike: {
    imagePath: images.siteNike,
    features: [
      {
        title: "FCFS & DRAWS Support",
        features: [
          "DESTROY any Nike release with Kodai. Whether it's a first-come-first-serve or a SNKRS draw release, Kodai has you covered!",
        ],
      },
      {
        title: "Browser Mode",
        features: [
          "Kodai's browser mode is the best of it's kind, allowing for HUNDREDS of accounts to be able to be entered every release with ease.",
        ],
      },
      {
        title: "REQUEST Mode",
        features: [
          "Not a fan of browsers? Use request mode instead and enter THOUSANDS of accounts per draw release with ZERO worry about account bans or filtering!",
        ],
      },
    ],
  },
  target: {
    imagePath: images.siteTarget,
    features: [
      {
        title: "Setup In SECONDS",
        features: [
          'Setup in literal seconds. No manual labor, like "harvesting", is required with Kodai!',
        ],
      },
      {
        title: "RED Card Support",
        features: [
          "Kodai supports checking out with a Target RED card for increased benefits.",
        ],
      },
      {
        title: "Item Quantity",
        features: [
          "Checkout multiple of your favorite products at once to save on shipping!",
        ],
      },
    ],
  },
  adidas: {
    imagePath: images.siteAdidas,
    features: [
      {
        title: "FCFS Support",
        features: [
          "With Kodai's secret techniques, you will DOMINATE any first-come-first-serve release with unbelievable checkout speeds!",
        ],
      },
      {
        title: "Waiting Room Support",
        features: [
          "Kodai DESTROYS waiting room releases. Run THOUSANDS of tasks without breaking a sweat on an average machine and automatically checkout without having to lift a finger!",
        ],
      },
      {
        title: "Account Support",
        features: [
          "Optionally login to an account when checking out to gain rewards points and early access drops.",
        ],
      },
    ],
  },
}

export interface SiteListItem {
  id: SiteType
  imagePath: string
}

export const SITE_LIST = getObjectKeys(SITES).reduce<SiteListItem[]>(
  (acc, key) => {
    acc.push({ id: key, imagePath: SITES[key].imagePath })
    return acc
  },
  [],
)
