import BOX from "./box.svg"
import CHECKMARK from "./checkmark.svg"
import CONGRATULATIONS from "./congratulations.svg"
import CURSOR from "./cursor.svg"
import ERROR from "./error.svg"
import GLOB from "./glob.svg"
import INSTA_LOGO from "./insta_logo.svg"
import LIGHTNING from "./lightning.svg"
import LOGO from "./logo.svg"
import LOGO_SECONDARY from "./logo_secondary.svg"
import NEW_RELEASE from "./new-release.svg"
import START from "./star.svg"
import TWITTER from "./twitter.svg"
import UP_RIGHT_ARROW from "./upRightArrow.svg"

export const icons = {
  logo: LOGO,
  logoSecondary: LOGO_SECONDARY,
  cursor: CURSOR,
  box: BOX,
  lightning: LIGHTNING,
  newRelease: NEW_RELEASE,
  checkmark: CHECKMARK,
  twitter: TWITTER,
  congratulations: CONGRATULATIONS,
  star: START,
  instagramLogo: INSTA_LOGO,
  upRightArrow: UP_RIGHT_ARROW,
  glob: GLOB,
  error: ERROR,
}
