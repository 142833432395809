export const generateRandomInRange = (
  startRange: number,
  endRange: number,
  exceptions: number[][],
) => {
  const allNumbers = Array.from(
    { length: Math.abs(endRange - startRange) + 1 },
    (_, i) => i + Math.min(startRange, endRange),
  )

  const filteredNumbers = allNumbers.filter((num) => {
    for (const exception of exceptions) {
      if (num >= exception[0] && num <= exception[1]) {
        return false
      }
    }
    return true
  })

  const randomIndex = Math.floor(Math.random() * filteredNumbers.length)

  const randomNum = filteredNumbers[randomIndex]

  return randomNum
}
