import { Button } from "components"

import { PaymentLayout } from "../../components"

import styles from "./AvailabilityForm.module.scss"

interface AvailabilityFormProps {
  className?: string
  onCheckAvailability: () => void
  minimizedMobileHeader: boolean
  onMaximize: () => void
  onMinimize: () => void
}
export const AvailabilityForm = ({
  className,
  onCheckAvailability,
  onMinimize,
  onMaximize,
  minimizedMobileHeader,
}: AvailabilityFormProps) => {
  return (
    <PaymentLayout
      className={className}
      expiredTime={null}
      minimizedMobileHeader={minimizedMobileHeader}
      title={{
        firstPart: "Check if Kodai is back in",
        secondPart: "!",
      }}
      titleUnderlined="stock"
      onMaximize={onMaximize}
      onMinimize={onMinimize}>
      <div className={styles.form}>
        <Button onClick={onCheckAvailability}>Check For Availability</Button>
        <div className={styles.caption}>
          Kodai is typically sold out due to heavy demand.
          <br />
          To check for availability, press the button above. If there is any,
          you will have a very limited opportunity to purchase. If there is no
          availability, you can enter the waitlist and be notified when Kodai is
          available again.
        </div>
      </div>
    </PaymentLayout>
  )
}
