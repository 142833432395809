import { CardColor } from "types/productCards"

interface CardStyles {
  boxShadow: string
  background: string
  glareColor: string
}

export const cardStyles: Record<CardColor, CardStyles> = {
  blue: {
    boxShadow: "inset 0px 1px 0px rgba(145, 106, 255, 0.5)",
    background:
      "linear-gradient(184.57deg, rgba(157, 111, 255, 0.08) -3.39%, rgba(255, 255, 255, 0) 105.34%)",
    glareColor: "rgba(145, 106, 255, 0.5)",
  },
  orange: {
    boxShadow: "inset 0px 1px 0px rgba(255, 142, 106, 0.5)",
    background:
      "linear-gradient(184.57deg, rgba(255, 180, 111, 0.08) -3.39%, rgba(255, 255, 255, 0) 105.34%)",

    glareColor: "rgba(255, 142, 106, 0.5)",
  },
  green: {
    boxShadow: "inset 0px 1px 0px rgba(106, 255, 210, 0.5)",
    background:
      "linear-gradient(184.57deg, rgba(111, 255, 194, 0.08) -3.39%, rgba(255, 255, 255, 0) 105.34%)",
    glareColor: "rgba(106, 255, 210, 0.5)",
  },
}
