import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import { useState } from "react"
import { RouterProvider } from "react-router-dom"

import { PurchaseProvider } from "./purchase/PurchaseProvider"
import { router } from "./router"

const mutationCache = new MutationCache({
  onError: (error, variables, context, mutation) => {
    if (mutation.options.onError) return

    // Log the error
    console.error("An error occurred:", error)

    if (error instanceof Error) {
      // Handle specific Error instances
      console.error("Error instance:", error)
    } else {
      // Handle other error types
      console.error("Unknown error:", error)
    }

    // Throw the error to propagate it further
    throw error
  },
})

export const Main = () => {
  const [queryClient] = useState(() => new QueryClient({ mutationCache }))

  return (
    <QueryClientProvider client={queryClient}>
      <PurchaseProvider>
        <RouterProvider router={router} />
      </PurchaseProvider>
    </QueryClientProvider>
  )
}
