import { useCallback, useState } from "react"

export const useModal = (defaultVisible = false) => {
  const [isOpen, setIsOpen] = useState(defaultVisible)

  const openModal = useCallback(() => setIsOpen(true), [])
  const closeModal = useCallback(() => setIsOpen(false), [])

  return { isOpen, openModal, closeModal }
}
