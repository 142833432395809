import { icons } from "assets/icons"
import { Button, TiltCard } from "components"
import { AnimatePresence, motion } from "framer-motion"
import { useMediaQuery } from "hooks"
import isNull from "lodash-es/isNull"
import { ReactNode } from "react"
import { cx } from "styles"

import styles from "./PaymentLayout.module.scss"
import { AnimatedWrapper, Timer } from "./components"
import { animateVariants, tiltSettings } from "./utils"

interface PaymentLayoutProps {
  className?: string
  children: React.ReactElement
  title: {
    firstPart: ReactNode
    secondPart?: ReactNode
  }
  titleUnderlined: ReactNode
  footerText?: string
  expiredTime: number | null
  minimizedMobileHeader: boolean
  onMaximize: () => void
  onMinimize: () => void
}

export const PaymentLayout = ({
  className,
  children,
  title,
  titleUnderlined,
  footerText,
  expiredTime,
  minimizedMobileHeader,
  onMaximize,
  onMinimize,
}: PaymentLayoutProps) => {
  const isTabletQuery = useMediaQuery("tablet")
  const isTimeExpired = isNull(expiredTime)

  const timerTicker = Array.from({ length: 20 }, (_, key) => (
    <Timer expiredTime={expiredTime} key={key} />
  ))

  return (
    <>
      <div className={cx(styles.root, className)}>
        <div className={cx(styles.mainForm)}>
          <div className={styles.logo}>
            {!isTabletQuery ? (
              <TiltCard className={styles.animatedLogo} settings={tiltSettings}>
                <img
                  alt="logo"
                  className={styles.logoIcon}
                  draggable={false}
                  src={icons.logo}
                />
              </TiltCard>
            ) : (
              <AnimatePresence initial={false}>
                {!minimizedMobileHeader && (
                  <motion.img
                    alt="logo"
                    animate={{
                      ...animateVariants.in,
                      height: "6.5rem",
                    }}
                    className={styles.logoIcon}
                    draggable={false}
                    exit={animateVariants.out}
                    initial={animateVariants.out}
                    src={icons.logo}
                    transition={animateVariants.transition}
                  />
                )}
              </AnimatePresence>
            )}

            <div className={styles.logoText}>Kodai</div>
          </div>
          <div className={styles.loginLinkContainer}>
            <a
              className={cx(styles.loginLink)}
              href="https://hub.kodai.io/"
              rel="noopener noreferrer"
              target="__blank">
              <div className={styles.loginLinkTitle}>
                <span className={styles.loginLinkText}>Customer Login</span>
                <img
                  alt=""
                  className={styles.loginLinkIcon}
                  draggable={false}
                  src={icons.upRightArrow}
                />
              </div>
            </a>
          </div>
          <div className={styles.content}>
            {!isTabletQuery && !isTimeExpired && (
              <div className={styles.timerTicker}>
                <div className={styles.timerTickerTrack}>{timerTicker}</div>
              </div>
            )}

            {isTabletQuery && !isTimeExpired && (
              <AnimatedWrapper
                className={styles.timerTicker}
                isVisible={!isTimeExpired}>
                <div className={styles.timerTickerTrack}>{timerTicker}</div>
              </AnimatedWrapper>
            )}

            <div className={styles.title}>
              <span className={styles.titleStart}>{title.firstPart}</span>{" "}
              <span className={styles.titleUnderlined}>{titleUnderlined}</span>
              <span className={styles.titleEnd}>{title.secondPart}</span>
            </div>
            {footerText && <div className={styles.caption}>{footerText}</div>}
            <AnimatedWrapper
              className={styles.expandButton}
              isVisible={minimizedMobileHeader && isTabletQuery}>
              <Button variant="fill" onClick={onMaximize}>
                Expand
              </Button>
            </AnimatedWrapper>

            {!isTabletQuery ? (
              <div className={styles.form}>{children}</div>
            ) : (
              <AnimatedWrapper
                className={styles.formContainer}
                isVisible={!minimizedMobileHeader}>
                <div className={styles.form}>{children}</div>
              </AnimatedWrapper>
            )}
          </div>
        </div>
        <AnimatedWrapper
          className={styles.animatedMinimizeButton}
          isVisible={!minimizedMobileHeader && isTabletQuery}>
          <div className={styles.minimizeButtonContainer}>
            <button
              className={styles.minimizeButton}
              onClick={() => onMinimize?.()}>
              Minimize window ↑{" "}
            </button>
          </div>
        </AnimatedWrapper>
      </div>
    </>
  )
}
