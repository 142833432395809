export const envConfig: Config = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
  LICENSE_KEY: process.env.REACT_APP_LICENSE_KEY,
}

export interface Config {
  BASE_URL?: string
  STRIPE_KEY?: string
  LICENSE_KEY?: string
}
