import GLOBE_BG from "./earth-night.jpg"
import GLOBE_ENVIRONMENT from "./night-sky.png"
import STARS from "./stars.png"

export const backgrounds = {
  // globe
  globe: GLOBE_BG,
  stars: STARS,
  globeEnvironment: GLOBE_ENVIRONMENT,
}
