import { useEffect, useRef } from "react"
import { cx } from "styles"
import { generateId } from "utils/generateId"

import styles from "./Button.module.scss"

const STARS_COUNT = 40

interface StarsProps {
  hover: boolean
  width: number
  height: number
}

export const Stars = ({ hover, width, height }: StarsProps) => {
  const svgRef = useRef<SVGSVGElement>(null)
  // each star component should have a unique id
  const id = generateId()
  const starId = `star-${id}`
  const starGroupId = `star-group-${id}`

  useEffect(() => {
    if (hover) {
      const svg = svgRef.current
      const svgNS = "http://www.w3.org/2000/svg"
      const xlinkNS = "http://www.w3.org/1999/xlink"
      const angles: number[] = []
      const distances: number[] = []
      const centerX = Number(svg?.getAttributeNS(null, "width") ?? 1) / 2
      const centerY = Number(svg?.getAttributeNS(null, "height") ?? 1) / 2
      const maxD = centerX + centerY

      const createStars = (n: number) => {
        const path = svg?.getElementById(starId)
        const pathId = path?.getAttributeNS(null, "id")
        const starGroupEl = document.getElementById(starGroupId)

        for (let i = 1; i <= n; i++) {
          const star = document.createElementNS(svgNS, "use")

          if (star) {
            star.setAttributeNS(null, "id", starId + i)
            star.setAttributeNS(xlinkNS, "href", "#" + pathId)
            if (starGroupEl) {
              starGroupEl.style.opacity = "1"
              starGroupEl.appendChild(star)
              angles.push(Math.PI * 2 * Math.random())
              distances.push(maxD * Math.random())
            }
          }
        }
      }

      const drawField = () => {
        if (svg) {
          for (let i = 0; i < STARS_COUNT; i++) {
            const star = svg.getElementById(starId + (i + 1))!
            const d = distances[i] < maxD ? distances[i] : maxD

            const x = centerX + d * Math.sin(angles[i])
            const y = centerY + d * Math.cos(angles[i])
            const scale = 2.4 * (1 - maxD / (d + maxD))

            if (star) {
              star.setAttributeNS(
                null,
                "transform",
                "translate(" + x + "," + y + ") scale(" + scale * scale + ")",
              )
              star.setAttributeNS(null, "position", "relative")
            }
          }
        }
      }
      const updateImage = () => {
        for (let i = 0; i < STARS_COUNT; i++) {
          distances[i]++
          if (distances[i] > maxD) {
            distances[i] = 0
          }
        }
        drawField()
      }

      createStars(STARS_COUNT)
      const interval = setInterval(updateImage, 60)

      return () => clearInterval(interval)
    } else {
      const starGroupEl = document.getElementById(starGroupId)

      if (starGroupEl) {
        starGroupEl.style.opacity = "0"
        // smooth disappearance
        setTimeout(() => {
          starGroupEl.innerHTML = ""
        }, 50)
      }
    }
  }, [hover, starId, starGroupId])

  return (
    <div className={cx(styles.stars)}>
      <svg
        className={styles.starsSvg}
        height={height}
        ref={svgRef}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <g clipPath="url(#clip0_40_2359)" id={starId}>
            <path
              d="M1.5 0L1.58498 0.515917C1.66099 0.977356 2.02264 1.33901 2.48408 1.41502L3 1.5L2.48408 1.58498C2.02264 1.66099 1.66099 2.02264 1.58498 2.48408L1.5 3L1.41502 2.48408C1.33901 2.02264 0.977356 1.66099 0.515917 1.58498L0 1.5L0.515917 1.41502C0.977356 1.33901 1.33901 0.977356 1.41502 0.515917L1.5 0Z"
              fill="#fff"
            />
          </g>
          <defs>
            <clipPath id="clip0_40_2359">
              <rect fill="#fff" height="3" width="3" />
            </clipPath>
          </defs>
        </defs>
        <g className={styles.starGroup} id={starGroupId} />
      </svg>
    </div>
  )
}
