import { AnimatePresence, motion } from "framer-motion"
import { ReactNode } from "react"

import { animateVariants } from "../../utils"

interface AnimatedWrapperProps {
  children: ReactNode
  className?: string
  isVisible: boolean
}

export const AnimatedWrapper = ({
  children,
  className,
  isVisible,
}: AnimatedWrapperProps) => {
  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <motion.div
          animate={animateVariants.in}
          className={className}
          exit={animateVariants.out}
          initial={animateVariants.out}
          transition={animateVariants.transition}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
