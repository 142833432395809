export enum NavigationTab {
  SUCCESS = "SUCCESS",
  SITELIST = "SITELIST",
  REGIONS = "REGIONS",
  // deleted due to twitter issues
  // SOCIALS = "SOCIALS",
}

export interface Country {
  key: string
  latitude: number
  longitude: number
  name: string
  flag: string
  customFlag?: string
}

export interface CountryPoint extends Country {
  color: string
}
