import { CSSProperties, ReactNode } from "react"
import Tilt, { ReactParallaxTiltProps } from "react-parallax-tilt"
import { cx } from "styles"
import { isTouchableDevice } from "utils/getDeviceType"

import styles from "./TiltCard.module.scss"
import { commonTiltProps } from "./config"

interface TiltCardProps {
  className?: string
  children: ReactNode
  settings?: ReactParallaxTiltProps
  style?: CSSProperties
}

export const TiltCard = ({ className, settings, children }: TiltCardProps) => {
  const tiltSetting = settings || commonTiltProps

  return (
    <Tilt
      {...tiltSetting}
      glareEnable
      className={cx(styles.root, className)}
      glarePosition="all"
      gyroscope={false}
      style={{ pointerEvents: isTouchableDevice() ? "none" : "initial" }}>
      {children}
    </Tilt>
  )
}
