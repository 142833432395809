import { icons } from "assets/icons"
import { Fragment } from "react"
import { cx } from "styles"
import { isTouchableDevice } from "utils/getDeviceType"

import styles from "./FeaturesDesktop.module.scss"

export interface FeatureType {
  features: string[]
  title: string
}

interface FeaturesDesktopProps {
  className?: string
  items: FeatureType[] | null
  headerIcons: string[]
  additionalListId: number | null
  setAdditionalListId: (id: number | null) => void
}

export const FeaturesDesktop = ({
  className,
  items,
  headerIcons,
  additionalListId,
  setAdditionalListId,
}: FeaturesDesktopProps) => {
  const onClickedColumnChange = (index: number) => {
    return setAdditionalListId(index === additionalListId ? null : index)
  }

  return (
    <div
      className={cx(styles.root, className)}
      // click outside does not work when this component is clicked
      onClick={(e) => e.stopPropagation()}>
      {!items?.length ? (
        <div className={styles.initial}>
          <div
            className={styles.initialIcon}
            style={{ backgroundImage: `url(${icons.cursor})` }}
          />
          <div className={styles.initialTitle}>Click a Site</div>
          <div>Click on a site Kodai supports to learn more about it.</div>
        </div>
      ) : items.length > 0 ? (
        <div className={styles.featuresContainer}>
          {items.map((item, index) => {
            const isLast = items.length - 1 === index

            return (
              <Fragment key={index}>
                <div
                  className={cx(styles.column)}
                  onClick={() => {
                    if (isTouchableDevice()) {
                      onClickedColumnChange(index)
                    }
                  }}
                  onMouseEnter={() => onClickedColumnChange(index)}
                  onMouseLeave={() => setAdditionalListId(null)}>
                  <div className={styles.iconArea}>
                    <div className={styles.iconContainer}>
                      <img
                        alt={item.title}
                        className={styles.icon}
                        draggable={false}
                        src={headerIcons[index]}
                      />
                    </div>
                  </div>
                  <div className={styles.titleArea}>
                    <div className={styles.title}>{item.title}</div>
                  </div>
                  <div className={styles.infoArea}>
                    {/* display only 2 less important features */}
                    <div className={styles.infoDescription}>
                      {item.features.slice(0, 2).join("\n")}
                    </div>
                  </div>
                  <div
                    className={cx(styles.additionalListContainer, {
                      [styles.additionalListActive]:
                        additionalListId === index && !!item.features.length,
                    })}>
                    <div className={styles.additionalListDescription}>
                      {item.features.join("\n")}
                    </div>
                  </div>
                </div>

                {!isLast && (
                  <div>
                    <div className={styles.columnDivider} />
                  </div>
                )}
              </Fragment>
            )
          })}
        </div>
      ) : (
        <div className={styles.emptyText}>No features</div>
      )}
    </div>
  )
}
