import { motion } from "framer-motion"
import { useMediaQuery, useOutsideClick, useResize } from "hooks"
import { useEffect, useMemo, useRef, useState } from "react"
import { cx } from "styles"
import { generateRandomInRange } from "utils/generateRandomInRange"
import { repeatAndFlatArray } from "utils/repeatArray"
import { shuffleArray } from "utils/shuffleArray"

import { SITE_LIST, SiteType } from "../../sitesInfo"

import styles from "./Sites.module.scss"

interface SitesProps {
  className?: string
  onClickSite: (site: SiteType | null) => void
  onReset: () => void
}

interface CenterPositionMap {
  [index: number]: {
    x: number
    y: number
    width: number
    height: number
  }
}

// can be changed
const ASPECT_RATIO = 0.9
// can be changed
const CARD_PROPORTION = 0.9

type Position = "left" | "right" | "center"

const CARD_POSITION_OPTIONS: Position[] = shuffleArray(
  repeatAndFlatArray<Position>(["left", "right", "center"], 5),
)

// margin
const tablet = {
  center: "0 auto",
  left: "0 auto 0 0",
  right: "0 0 0 auto",
}

// shift to the following random coordinates
const shiftToRandomCardPositions = (size: number) => {
  const coordinateDivider = 4.5

  const minShift = size / -coordinateDivider
  const maxShift = size / coordinateDivider

  return generateRandomInRange(minShift, maxShift, [])
}

export const Sites = ({ className, onClickSite, onReset }: SitesProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const isMobile = useMediaQuery("mobile")
  const isSmallMobile = useMediaQuery("mobileSmall")
  const isTablet = useMediaQuery("tablet")
  const [centerPositionMap, setCenterPositionMap] =
    useState<CenterPositionMap>()
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null)

  const FEATURE_HEIGHT = isMobile ? 120 : isTablet ? 150 : 20

  const resetPosition = () => {
    handleCardClick(null)
    onReset()
  }

  const { ref } = useOutsideClick(resetPosition)
  const sizes = useResize(containerRef)

  const rectanglePositions = useMemo(() => {
    const isSelected = selectedCardId !== null && !!centerPositionMap

    if (isSelected) {
      const positionMapLength = Object.keys(centerPositionMap).length

      const containerWidth =
        containerRef.current?.getBoundingClientRect().width ?? 1
      const containerHeight =
        (containerRef.current?.getBoundingClientRect().height ?? 1) -
        FEATURE_HEIGHT

      const containerWidthCenter = (containerWidth ?? 1) / 2
      const containerHeightCenter = (containerHeight ?? 1) / 2

      // calculation of equal angles between cards
      const angleBetween =
        2 * Math.atan(Math.tan(Math.PI / (positionMapLength - 1)))

      // center the selected item
      const clickedCard = {
        x: containerWidthCenter,
        y: containerHeightCenter,
        width: centerPositionMap[selectedCardId].width,
        height: centerPositionMap[selectedCardId].height,
      }

      const centerX = clickedCard.x
      const centerY = clickedCard.y
      const itemAmountInRow = isTablet ? 3 : 2
      const radiusX =
        containerWidthCenter -
        centerPositionMap[selectedCardId].width / itemAmountInRow

      const FEATURE_CONTAINER_HEIGHT = isSmallMobile ? 0 : 25

      // container offset
      const radiusY = containerHeightCenter - FEATURE_CONTAINER_HEIGHT

      const newCenterPositionMap = { ...centerPositionMap }
      let cardNumber = 0

      SITE_LIST.forEach((_, index) => {
        if (index === selectedCardId) {
          newCenterPositionMap[selectedCardId] = clickedCard
        } else {
          const x = centerX + radiusX * Math.cos(angleBetween * cardNumber)
          const y = centerY + radiusY * Math.sin(angleBetween * cardNumber)

          newCenterPositionMap[index] = {
            x,
            y,
            width: centerPositionMap[index].width,
            height: centerPositionMap[index].height,
          }
          cardNumber++
        }
      })
      return newCenterPositionMap
    } else {
      return centerPositionMap
    }
  }, [
    selectedCardId,
    centerPositionMap,
    isTablet,
    FEATURE_HEIGHT,
    isSmallMobile,
  ])

  useEffect(() => {
    const getGrid = () => {
      if (isMobile) {
        const columns = 2
        const rows = SITE_LIST.length / columns
        return {
          rows,
          columns,
        }
      }

      const rows = Math.sqrt(SITE_LIST.length / 2)
      const columns = Math.ceil(Math.ceil(rows) * 1.5)
      return {
        rows,
        columns,
      }
    }

    if (containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width
      const containerHeight =
        containerRef.current.getBoundingClientRect().height

      const cardsLength = SITE_LIST.length

      const rows = getGrid().rows
      const columns = getGrid().columns

      const cardW = (ASPECT_RATIO * containerWidth) / columns
      const cardH = (containerHeight / Math.floor(rows)) * CARD_PROPORTION
      const cardWCenter = cardW / 2
      const cardHCenter = cardH / 2

      const positionMap = [
        ...Array(SITE_LIST.length),
      ].reduce<CenterPositionMap>((acc, _, index) => {
        const rowIndex = Math.floor(index / columns)
        const colIndex = index % columns
        const unfilledRowCardsCount = cardsLength % columns

        // store the coordinates of the center
        const x = cardW * colIndex + cardWCenter
        const y = cardH * rowIndex + cardHCenter

        if (
          unfilledRowCardsCount &&
          cardsLength - unfilledRowCardsCount <= index
        ) {
          const isEvenCount = unfilledRowCardsCount % 2 === 0
          const center = columns / 2 - 0.5
          const shift =
            unfilledRowCardsCount === 1
              ? center
              : !isEvenCount
              ? 1
              : (columns - unfilledRowCardsCount) / 2

          acc[index] = {
            x:
              (cardW * (colIndex + shift) +
                cardWCenter +
                shiftToRandomCardPositions(cardW)) /
              CARD_PROPORTION,
            y: (y + shiftToRandomCardPositions(cardH)) / CARD_PROPORTION,
            width: cardW,
            height: cardH,
          }
        } else {
          acc[index] = {
            x: (x + shiftToRandomCardPositions(cardW)) / CARD_PROPORTION,
            width: cardW,
            y: (y + shiftToRandomCardPositions(cardH)) / CARD_PROPORTION,
            height: cardH,
          }
        }
        return acc
      }, {})

      setCenterPositionMap(positionMap)
    }
  }, [
    isMobile,
    isTablet,
    containerRef.current?.clientHeight,
    containerRef.current?.clientWidth,
    sizes,
  ])

  const handleCardClick = (clickedRectIndex: number | null) => {
    setSelectedCardId(clickedRectIndex)
    onClickSite(
      clickedRectIndex !== null ? SITE_LIST[clickedRectIndex].id : null,
    )
  }

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.container} ref={containerRef}>
        {rectanglePositions ? (
          <>
            {SITE_LIST.map((card, index) => {
              const w = rectanglePositions[index].width
              const h = rectanglePositions[index].height / 2
              const sizeIncrement = isTablet ? 1.3 : 1.5
              const sizeReduction = 0.7
              const isCardSelected = index === selectedCardId

              const size =
                selectedCardId === null
                  ? {
                      width: w,
                      height: h,
                    }
                  : isCardSelected
                  ? {
                      width: w * sizeIncrement,
                      height: h * sizeIncrement,
                    }
                  : {
                      width: w * sizeReduction,
                      height: h * sizeReduction,
                    }

              return (
                <motion.div
                  animate={{
                    width: size.width,
                    height: size.height,
                    opacity:
                      selectedCardId === null ? 1 : isCardSelected ? 0.8 : 0.5,
                    x: rectanglePositions[index].x - size.width / 2,
                    y: rectanglePositions[index].y - size.height / 2,
                  }}
                  className={styles.card}
                  initial={false}
                  key={index}
                  ref={ref}
                  transition={{
                    opacity: { duration: 0.3 },
                    scale: { type: "spring", stiffness: 400, damping: 10 },
                    bounce: 0,
                  }}>
                  <motion.img
                    className={cx(styles.cardImage, {
                      [styles.selectedCard]: isCardSelected,
                    })}
                    draggable={false}
                    src={card.imagePath}
                    style={
                      isTablet && selectedCardId === null
                        ? {
                            margin: tablet[CARD_POSITION_OPTIONS[index]],
                          }
                        : {}
                    }
                    whileHover={{
                      scale: 1.3,
                      opacity: 1,
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      return handleCardClick(index)
                    }}
                  />
                </motion.div>
              )
            })}
          </>
        ) : (
          <div className={styles.emptyText}>No sites</div>
        )}
      </div>
    </div>
  )
}
