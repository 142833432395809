import { flags } from "assets/icons/flags"

export const countries = [
  {
    key: "AD",
    latitude: 42.546245,
    longitude: 1.601554,
    name: "Andorra",
    flag: "🇦🇩",
  },
  {
    key: "AE",
    latitude: 23.424076,
    longitude: 53.847818,
    name: "United Arab Emirates",
    flag: "🇦🇪",
  },
  {
    key: "AF",
    latitude: 33.93911,
    longitude: 67.709953,
    name: "Afghanistan",
    flag: "🇦🇫",
  },
  {
    key: "AG",
    latitude: 17.060816,
    longitude: -61.796428,
    name: "Antigua and Barbuda",
    flag: "🇦🇬",
  },
  {
    key: "AI",
    latitude: 18.220554,
    longitude: -63.068615,
    name: "Anguilla",
    flag: "🇦🇮",
  },
  {
    key: "AL",
    latitude: 41.153332,
    longitude: 20.168331,
    name: "Albania",
    flag: "🇦🇱",
  },
  {
    key: "AM",
    latitude: 40.069099,
    longitude: 45.038189,
    name: "Armenia",
    flag: "🇦🇲",
  },
  {
    key: "AN",
    latitude: 12.226079,
    longitude: -69.060087,
    name: "Netherlands Antilles",
    flag: "🇳🇱",
  },
  {
    key: "AO",
    latitude: -11.202692,
    longitude: 17.873887,
    name: "Angola",
    flag: "🇦🇴",
  },
  {
    key: "AQ",
    latitude: -75.250973,
    longitude: -0.071389,
    name: "Antarctica",
    flag: "🇦🇶",
  },
  {
    key: "AR",
    latitude: -38.416097,
    longitude: -63.616672,
    name: "Argentina",
    flag: "🇦🇷",
  },
  {
    key: "AS",
    latitude: -14.270972,
    longitude: -170.132217,
    name: "American Samoa",
    flag: "🇦🇸",
  },
  {
    key: "AT",
    latitude: 47.516231,
    longitude: 14.550072,
    name: "Austria",
    flag: "AT",
    customFlag: flags.AT,
  },
  {
    key: "AU",
    latitude: -25.274398,
    longitude: 133.775136,
    name: "Australia",
    flag: "🇦🇺",
    customFlag: flags.AU,
  },
  {
    key: "AW",
    latitude: 12.52111,
    longitude: -69.968338,
    name: "Aruba",
    flag: "🇦🇼",
  },
  {
    key: "AX",
    latitude: 60.3385485,
    longitude: 20.2712585,
    name: "Åland",
    flag: "🇦🇽",
  },
  {
    key: "AZ",
    latitude: 40.143105,
    longitude: 47.576927,
    name: "Azerbaijan",
    flag: "🇦🇿",
  },
  {
    key: "BA",
    latitude: 43.915886,
    longitude: 17.679076,
    name: "Bosnia and Herzegovina",
    flag: "🇧🇦",
  },
  {
    key: "BB",
    latitude: 13.193887,
    longitude: -59.543198,
    name: "Barbados",
    flag: "🇧🇧",
  },
  {
    key: "BD",
    latitude: 23.684994,
    longitude: 90.356331,
    name: "Bangladesh",
    flag: "🇧🇩",
  },
  {
    key: "BE",
    latitude: 50.503887,
    longitude: 4.469936,
    name: "Belgium",
    flag: "BE",
    customFlag: flags.BE,
  },
  {
    key: "BF",
    latitude: 12.238333,
    longitude: -1.561593,
    name: "Burkina Faso",
    flag: "🇧🇫",
  },
  {
    key: "BG",
    latitude: 42.733883,
    longitude: 25.48583,
    name: "Bulgaria",
    flag: "🇧🇬",
    customFlag: flags.BE,
  },
  {
    key: "BH",
    latitude: 25.930414,
    longitude: 50.637772,
    name: "Bahrain",
    flag: "🇧🇭",
  },
  {
    key: "BI",
    latitude: -3.373056,
    longitude: 29.918886,
    name: "Burundi",
    flag: "🇧🇮",
  },
  {
    key: "BJ",
    latitude: 9.30769,
    longitude: 2.315834,
    name: "Benin",
    flag: "🇧🇯",
  },
  {
    key: "BL",
    latitude: 17.9,
    longitude: -62.833333,
    name: "Saint-Barthélemy",
    flag: "🇧🇱",
  },
  {
    key: "BM",
    latitude: 32.321384,
    longitude: -64.75737,
    name: "Bermuda",
    flag: "🇧🇲",
  },
  {
    key: "BN",
    latitude: 4.535277,
    longitude: 114.727669,
    name: "Brunei",
    flag: "🇧🇳",
  },
  {
    key: "BO",
    latitude: -16.290154,
    longitude: -63.588653,
    name: "Bolivia",
    flag: "🇧🇴",
  },
  {
    key: "BQ",
    latitude: 12.1783611,
    longitude: -68.2385339,
    name: "Bonaire, Sint Eustatius, and Saba",
    flag: "🇧🇶",
  },
  {
    key: "BR",
    latitude: -14.235004,
    longitude: -51.92528,
    name: "Brazil",
    flag: "🇧🇷",
    customFlag: flags.BR,
  },
  {
    key: "BS",
    latitude: 25.03428,
    longitude: -77.39628,
    name: "Bahamas",
    flag: "🇧🇸",
  },
  {
    key: "BT",
    latitude: 27.514162,
    longitude: 90.433601,
    name: "Bhutan",
    flag: "🇧🇹",
  },
  {
    key: "BV",
    latitude: -54.423199,
    longitude: 3.413194,
    name: "Bouvet Island",
    flag: "🇧🇻",
  },
  {
    key: "BW",
    latitude: -22.328474,
    longitude: 24.684866,
    name: "Botswana",
    flag: "🇧🇼",
  },
  {
    key: "BY",
    latitude: 53.709807,
    longitude: 27.953389,
    name: "Belarus",
    flag: "🇧🇾",
  },
  {
    key: "BZ",
    latitude: 17.189877,
    longitude: -88.49765,
    name: "Belize",
    flag: "🇧🇿",
  },
  {
    key: "CA",
    latitude: 56.130366,
    longitude: -106.346771,
    name: "Canada",
    flag: "🇨🇦",
    customFlag: flags.CA,
  },
  {
    key: "CC",
    latitude: -12.164165,
    longitude: 96.870956,
    name: "Cocos [Keeling] Islands",
    flag: "🇨🇨",
  },
  {
    key: "CD",
    latitude: -4.038333,
    longitude: 21.758664,
    name: "Congo [DRC]",
    flag: "🇨🇩",
  },
  {
    key: "CF",
    latitude: 6.611111,
    longitude: 20.939444,
    name: "Central African Republic",
    flag: "🇨🇫",
  },
  {
    key: "CG",
    latitude: -0.228021,
    longitude: 15.827659,
    name: "Congo [Republic]",
    flag: "🇨🇬",
  },
  {
    key: "CH",
    latitude: 46.818188,
    longitude: 8.227512,
    name: "Switzerland",
    flag: "🇨🇭",
    customFlag: flags.CH,
  },
  {
    key: "CI",
    latitude: 7.539989,
    longitude: -5.54708,
    name: "Côte d'Ivoire",
    flag: "🇨🇮",
  },
  {
    key: "CK",
    latitude: -21.236736,
    longitude: -159.777671,
    name: "Cook Islands",
    flag: "🇨🇰",
  },
  {
    key: "CL",
    latitude: -35.675147,
    longitude: -71.542969,
    name: "Chile",
    flag: "🇨🇱",
  },
  {
    key: "CM",
    latitude: 7.369722,
    longitude: 12.354722,
    name: "Cameroon",
    flag: "🇨🇲",
  },
  {
    key: "CN",
    latitude: 35.86166,
    longitude: 104.195397,
    name: "China",
    flag: "🇨🇳",
    customFlag: flags.CN,
  },
  {
    key: "CO",
    latitude: 4.570868,
    longitude: -74.297333,
    name: "Colombia",
    flag: "🇨🇴",
  },
  {
    key: "CR",
    latitude: 9.748917,
    longitude: -83.753428,
    name: "Costa Rica",
    flag: "🇨🇷",
  },
  {
    key: "CU",
    latitude: 21.521757,
    longitude: -77.781167,
    name: "Cuba",
    flag: "🇨🇺",
  },
  {
    key: "CV",
    latitude: 16.002082,
    longitude: -24.013197,
    name: "Cape Verde",
    flag: "🇨🇻",
  },
  {
    key: "CW",
    latitude: 12.16957,
    longitude: -68.99002,
    name: "Curaçao",
    flag: "🇨🇼",
  },
  {
    key: "CX",
    latitude: -10.447525,
    longitude: 105.690449,
    name: "Christmas Island",
    flag: "🇨🇽",
  },
  {
    key: "CY",
    latitude: 35.126413,
    longitude: 33.429859,
    name: "Cyprus",
    flag: "🇨🇾",
  },
  {
    key: "CZ",
    latitude: 49.817492,
    longitude: 15.472962,
    name: "Czech Republic",
    flag: "🇨🇿",
    customFlag: flags.CZ,
  },
  {
    key: "DE",
    latitude: 51.165691,
    longitude: 10.451526,
    name: "Germany",
    flag: "🇩🇪",
    customFlag: flags.DE,
  },
  {
    key: "DJ",
    latitude: 11.825138,
    longitude: 42.590275,
    name: "Djibouti",
    flag: "🇩🇯",
  },
  {
    key: "DK",
    latitude: 56.26392,
    longitude: 9.501785,
    name: "Denmark",
    flag: "🇩🇰",
    customFlag: flags.DK,
  },
  {
    key: "DM",
    latitude: 15.414999,
    longitude: -61.370976,
    name: "Dominica",
    flag: "🇩🇲",
  },
  {
    key: "DO",
    latitude: 18.735693,
    longitude: -70.162651,
    name: "Dominican Republic",
    flag: "🇩🇴",
  },
  {
    key: "DZ",
    latitude: 28.033886,
    longitude: 1.659626,
    name: "Algeria",
    flag: "🇩🇿",
  },
  {
    key: "EC",
    latitude: -1.831239,
    longitude: -78.183406,
    name: "Ecuador",
    flag: "🇪🇨",
  },
  {
    key: "EE",
    latitude: 58.595272,
    longitude: 25.013607,
    name: "Estonia",
    flag: "🇪🇪",
  },
  {
    key: "EG",
    latitude: 26.820553,
    longitude: 30.802498,
    name: "Egypt",
    flag: "🇪🇬",
  },
  {
    key: "EH",
    latitude: 24.215527,
    longitude: -12.885834,
    name: "Western Sahara",
    flag: "🇪🇭",
  },
  {
    key: "ER",
    latitude: 15.179384,
    longitude: 39.782334,
    name: "Eritrea",
    flag: "🇪🇷",
  },
  {
    key: "ES",
    latitude: 40.463667,
    longitude: -3.74922,
    name: "Spain",
    flag: "🇪🇸",
    customFlag: flags.ES,
  },
  {
    key: "ET",
    latitude: 9.145,
    longitude: 40.489673,
    name: "Ethiopia",
    flag: "🇪🇹",
  },
  {
    key: "FI",
    latitude: 61.92411,
    longitude: 25.748151,
    name: "Finland",
    flag: "🇫🇮",
    customFlag: flags.FI,
  },
  {
    key: "FJ",
    latitude: -16.578193,
    longitude: 179.414413,
    name: "Fiji",
    flag: "🇫🇯",
  },
  {
    key: "FK",
    latitude: -51.796253,
    longitude: -59.523613,
    name: "Falkland Islands [Islas Malvinas]",
    flag: "🇫🇰",
  },
  {
    key: "FM",
    latitude: 7.425554,
    longitude: 150.550812,
    name: "Micronesia",
    flag: "🇫🇲",
  },
  {
    key: "FO",
    latitude: 61.892635,
    longitude: -6.911806,
    name: "Faroe Islands",
    flag: "🇫🇴",
  },
  {
    key: "FR",
    latitude: 46.227638,
    longitude: 2.213749,
    name: "France",
    flag: "🇫🇷",
    customFlag: flags.FR,
  },
  {
    key: "GA",
    latitude: -0.803689,
    longitude: 11.609444,
    name: "Gabon",
    flag: "🇬🇦",
  },
  {
    key: "GB",
    latitude: 55.378051,
    longitude: -3.435973,
    name: "United Kingdom",
    flag: "🇬🇧",
  },
  {
    key: "GD",
    latitude: 12.262776,
    longitude: -61.604171,
    name: "Grenada",
    flag: "🇬🇩",
  },
  {
    key: "GE",
    latitude: 42.315407,
    longitude: 43.356892,
    name: "Georgia",
    flag: "🇬🇪",
  },
  {
    key: "GF",
    latitude: 3.933889,
    longitude: -53.125782,
    name: "French Guiana",
    flag: "🇬🇫",
  },
  {
    key: "GG",
    latitude: 49.465691,
    longitude: -2.585278,
    name: "Guernsey",
    flag: "🇬🇬",
  },
  {
    key: "GH",
    latitude: 7.946527,
    longitude: -1.023194,
    name: "Ghana",
    flag: "🇬🇭",
  },
  {
    key: "GI",
    latitude: 36.137741,
    longitude: -5.345374,
    name: "Gibraltar",
    flag: "🇬🇮",
  },
  {
    key: "GL",
    latitude: 71.706936,
    longitude: -42.604303,
    name: "Greenland",
    flag: "🇬🇱",
  },
  {
    key: "GM",
    latitude: 13.443182,
    longitude: -15.310139,
    name: "Gambia",
    flag: "🇬🇲",
  },
  {
    key: "GN",
    latitude: 9.945587,
    longitude: -9.696645,
    name: "Guinea",
    flag: "🇬🇳",
  },
  {
    key: "GP",
    latitude: 16.995971,
    longitude: -62.067641,
    name: "Guadeloupe",
    flag: "🇬🇵",
  },
  {
    key: "GQ",
    latitude: 1.650801,
    longitude: 10.267895,
    name: "Equatorial Guinea",
    flag: "🇬🇶",
  },
  {
    key: "GR",
    latitude: 39.074208,
    longitude: 21.824312,
    name: "Greece",
    flag: "🇬🇷",
    customFlag: flags.GR,
  },
  {
    key: "GS",
    latitude: -54.429579,
    longitude: -36.587909,
    name: "South Georgia and the South Sandwich Islands",
    flag: "🇬🇸",
  },
  {
    key: "GT",
    latitude: 15.783471,
    longitude: -90.230759,
    name: "Guatemala",
    flag: "🇬🇹",
  },
  {
    key: "GU",
    latitude: 13.444304,
    longitude: 144.793731,
    name: "Guam",
    flag: "🇬🇺",
  },
  {
    key: "GW",
    latitude: 11.803749,
    longitude: -15.180413,
    name: "Guinea-Bissau",
    flag: "🇬🇼",
  },
  {
    key: "GY",
    latitude: 4.860416,
    longitude: -58.93018,
    name: "Guyana",
    flag: "🇬🇾",
  },
  {
    key: "GZ",
    latitude: 31.354676,
    longitude: 34.308825,
    name: "Gaza Strip",
    flag: "🇬🇵",
  },
  {
    key: "HK",
    latitude: 22.396428,
    longitude: 114.109497,
    name: "Hong Kong",
    flag: "🇭🇰",
    customFlag: flags.HK,
  },
  {
    key: "HM",
    latitude: -53.08181,
    longitude: 73.504158,
    name: "Heard Island and McDonald Islands",
    flag: "🇭🇲",
  },
  {
    key: "HN",
    latitude: 15.199999,
    longitude: -86.241905,
    name: "Honduras",
    flag: "🇭🇳",
  },
  { key: "HR", latitude: 45.1, longitude: 15.2, name: "Croatia", flag: "🇭🇷" },
  {
    key: "HT",
    latitude: 18.971187,
    longitude: -72.285215,
    name: "Haiti",
    flag: "🇭🇹",
  },
  {
    key: "HU",
    latitude: 47.162494,
    longitude: 19.503304,
    name: "Hungary",
    flag: "🇭🇺",
    customFlag: flags.HU,
  },
  {
    key: "ID",
    latitude: -0.789275,
    longitude: 113.921327,
    name: "Indonesia",
    flag: "🇮🇩",
    customFlag: flags.ID,
  },
  {
    key: "IE",
    latitude: 53.41291,
    longitude: -8.24389,
    name: "Ireland",
    flag: "🇮🇪",
    customFlag: flags.IE,
  },
  {
    key: "IL",
    latitude: 31.046051,
    longitude: 34.851612,
    name: "Israel",
    flag: "🇮🇱",
    customFlag: flags.IL,
  },
  {
    key: "IM",
    latitude: 54.236107,
    longitude: -4.548056,
    name: "Isle of Man",
    flag: "🇮🇲",
  },
  {
    key: "IN",
    latitude: 20.593684,
    longitude: 78.96288,
    name: "India",
    flag: "🇮🇳",
    customFlag: flags.IN,
  },
  {
    key: "IO",
    latitude: -6.343194,
    longitude: 71.876519,
    name: "British Indian Ocean Territory",
    flag: "🇮🇴",
  },
  {
    key: "IQ",
    latitude: 33.223191,
    longitude: 43.679291,
    name: "Iraq",
    flag: "🇮🇶",
  },
  {
    key: "IR",
    latitude: 32.427908,
    longitude: 53.688046,
    name: "Iran",
    flag: "🇮🇷",
  },
  {
    key: "IS",
    latitude: 64.963051,
    longitude: -19.020835,
    name: "Iceland",
    flag: "🇮🇸",
  },
  {
    key: "IT",
    latitude: 41.87194,
    longitude: 12.56738,
    name: "Italy",
    flag: "🇮🇹",
    customFlag: flags.IT,
  },
  {
    key: "JE",
    latitude: 49.214439,
    longitude: -2.13125,
    name: "Jersey",
    flag: "🇯🇪",
  },
  {
    key: "JM",
    latitude: 18.109581,
    longitude: -77.297508,
    name: "Jamaica",
    flag: "🇯🇲",
  },
  {
    key: "JO",
    latitude: 30.585164,
    longitude: 36.238414,
    name: "Jordan",
    flag: "🇯🇴",
  },
  {
    key: "JP",
    latitude: 36.204824,
    longitude: 138.252924,
    name: "Japan",
    flag: "🇯🇵",
    customFlag: flags.JP,
  },
  {
    key: "KE",
    latitude: -0.023559,
    longitude: 37.906193,
    name: "Kenya",
    flag: "🇰🇪",
  },
  {
    key: "KG",
    latitude: 41.20438,
    longitude: 74.766098,
    name: "Kyrgyzstan",
    flag: "🇰🇬",
  },
  {
    key: "KH",
    latitude: 12.565679,
    longitude: 104.990963,
    name: "Cambodia",
    flag: "🇰🇭",
  },
  {
    key: "KI",
    latitude: -3.370417,
    longitude: -168.734039,
    name: "Kiribati",
    flag: "🇰🇮",
  },
  {
    key: "KM",
    latitude: -11.875001,
    longitude: 43.872219,
    name: "Comoros",
    flag: "🇰🇲",
  },
  {
    key: "KN",
    latitude: 17.357822,
    longitude: -62.782998,
    name: "Saint Kitts and Nevis",
    flag: "🇰🇳",
  },
  {
    key: "KP",
    latitude: 40.339852,
    longitude: 127.510093,
    name: "North Korea",
    flag: "🇰🇵",
  },
  {
    key: "KR",
    latitude: 35.907757,
    longitude: 127.766922,
    name: "South Korea",
    flag: "🇰🇷",
    customFlag: flags.KR,
  },
  {
    key: "KW",
    latitude: 29.31166,
    longitude: 47.481766,
    name: "Kuwait",
    flag: "🇰🇼",
  },
  {
    key: "KY",
    latitude: 19.513469,
    longitude: -80.566956,
    name: "Cayman Islands",
    flag: "🇰🇾",
  },
  {
    key: "KZ",
    latitude: 48.019573,
    longitude: 66.923684,
    name: "Kazakhstan",
    flag: "🇰🇿",
  },
  {
    key: "LA",
    latitude: 19.85627,
    longitude: 102.495496,
    name: "Laos",
    flag: "🇱🇦",
  },
  {
    key: "LB",
    latitude: 33.854721,
    longitude: 35.862285,
    name: "Lebanon",
    flag: "🇱🇧",
  },
  {
    key: "LC",
    latitude: 13.909444,
    longitude: -60.978893,
    name: "Saint Lucia",
    flag: "🇱🇨",
  },
  {
    key: "LI",
    latitude: 47.166,
    longitude: 9.555373,
    name: "Liechtenstein",
    flag: "🇱🇮",
  },
  {
    key: "LK",
    latitude: 7.873054,
    longitude: 80.771797,
    name: "Sri Lanka",
    flag: "🇱🇰",
  },
  {
    key: "LR",
    latitude: 6.428055,
    longitude: -9.429499,
    name: "Liberia",
    flag: "🇱🇷",
  },
  {
    key: "LS",
    latitude: -29.609988,
    longitude: 28.233608,
    name: "Lesotho",
    flag: "🇱🇸",
  },
  {
    key: "LT",
    latitude: 55.169438,
    longitude: 23.881275,
    name: "Lithuania",
    flag: "🇱🇹",
    customFlag: flags.LT,
  },
  {
    key: "LU",
    latitude: 49.815273,
    longitude: 6.129583,
    name: "Luxembourg",
    flag: "🇱🇺",
    customFlag: flags.LU,
  },
  {
    key: "LV",
    latitude: 56.879635,
    longitude: 24.603189,
    name: "Latvia",
    flag: "🇱🇻",
  },
  {
    key: "LY",
    latitude: 26.3351,
    longitude: 17.228331,
    name: "Libya",
    flag: "🇱🇾",
  },
  {
    key: "MA",
    latitude: 31.791702,
    longitude: -7.09262,
    name: "Morocco",
    flag: "🇲🇦",
  },
  {
    key: "MC",
    latitude: 43.750298,
    longitude: 7.412841,
    name: "Monaco",
    flag: "🇲🇨",
  },
  {
    key: "MD",
    latitude: 47.411631,
    longitude: 28.369885,
    name: "Moldova",
    flag: "🇲🇩",
  },
  {
    key: "ME",
    latitude: 42.708678,
    longitude: 19.37439,
    name: "Montenegro",
    flag: "🇲🇪",
  },
  {
    key: "MF",
    latitude: 18.08255,
    longitude: -63.052251,
    name: "Saint Martin",
    flag: "🇲🇫",
  },
  {
    key: "MG",
    latitude: -18.766947,
    longitude: 46.869107,
    name: "Madagascar",
    flag: "🇲🇬",
  },
  {
    key: "MH",
    latitude: 7.131474,
    longitude: 171.184478,
    name: "Marshall Islands",
    flag: "🇲🇭",
  },
  {
    key: "MK",
    latitude: 41.608635,
    longitude: 21.745275,
    name: "Macedonia [FYROM]",
    flag: "🇲🇰",
  },
  {
    key: "ML",
    latitude: 17.570692,
    longitude: -3.996166,
    name: "Mali",
    flag: "🇲🇱",
  },
  {
    key: "MM",
    latitude: 21.913965,
    longitude: 95.956223,
    name: "Myanmar [Burma]",
    flag: "🇲🇲",
  },
  {
    key: "MN",
    latitude: 46.862496,
    longitude: 103.846656,
    name: "Mongolia",
    flag: "🇲🇳",
  },
  {
    key: "MO",
    latitude: 22.198745,
    longitude: 113.543873,
    name: "Macau",
    flag: "🇲🇴",
    customFlag: flags.MO,
  },
  {
    key: "MP",
    latitude: 17.33083,
    longitude: 145.38469,
    name: "Northern Mariana Islands",
    flag: "🇲🇵",
  },
  {
    key: "MQ",
    latitude: 14.641528,
    longitude: -61.024174,
    name: "Martinique",
    flag: "🇲🇶",
  },
  {
    key: "MR",
    latitude: 21.00789,
    longitude: -10.940835,
    name: "Mauritania",
    flag: "🇲🇷",
  },
  {
    key: "MS",
    latitude: 16.742498,
    longitude: -62.187366,
    name: "Montserrat",
    flag: "🇲🇸",
  },
  {
    key: "MT",
    latitude: 35.937496,
    longitude: 14.375416,
    name: "Malta",
    flag: "🇲🇹",
  },
  {
    key: "MU",
    latitude: -20.348404,
    longitude: 57.552152,
    name: "Mauritius",
    flag: "🇲🇺",
  },
  {
    key: "MV",
    latitude: 3.202778,
    longitude: 73.22068,
    name: "Maldives",
    flag: "🇲🇻",
  },
  {
    key: "MW",
    latitude: -13.254308,
    longitude: 34.301525,
    name: "Malawi",
    flag: "🇲🇼",
  },
  {
    key: "MX",
    latitude: 23.634501,
    longitude: -102.552784,
    name: "Mexico",
    flag: "🇲🇽",
    customFlag: flags.MX,
  },
  {
    key: "MY",
    latitude: 4.210484,
    longitude: 101.975766,
    name: "Malaysia",
    flag: "🇲🇾",
    customFlag: flags.MY,
  },
  {
    key: "MZ",
    latitude: -18.665695,
    longitude: 35.529562,
    name: "Mozambique",
    flag: "🇲🇿",
  },
  {
    key: "NA",
    latitude: -22.95764,
    longitude: 18.49041,
    name: "Namibia",
    flag: "🇳🇦",
  },
  {
    key: "NC",
    latitude: -20.904305,
    longitude: 165.618042,
    name: "New Caledonia",
    flag: "🇳🇨",
  },
  {
    key: "NE",
    latitude: 17.607789,
    longitude: 8.081666,
    name: "Niger",
    flag: "🇳🇪",
  },
  {
    key: "NF",
    latitude: -29.040835,
    longitude: 167.954712,
    name: "Norfolk Island",
    flag: "🇳🇫",
  },
  {
    key: "NG",
    latitude: 9.081999,
    longitude: 8.675277,
    name: "Nigeria",
    flag: "🇳🇬",
  },
  {
    key: "NI",
    latitude: 12.865416,
    longitude: -85.207229,
    name: "Nicaragua",
    flag: "🇳🇮",
  },
  {
    key: "NL",
    latitude: 52.132633,
    longitude: 5.291266,
    name: "Netherlands",
    flag: "🇳🇱",
    customFlag: flags.NL,
  },
  {
    key: "NO",
    latitude: 60.472024,
    longitude: 8.468946,
    name: "Norway",
    flag: "🇳🇴",
    customFlag: flags.NO,
  },
  {
    key: "NP",
    latitude: 28.394857,
    longitude: 84.124008,
    name: "Nepal",
    flag: "🇳🇵",
  },
  {
    key: "NR",
    latitude: -0.522778,
    longitude: 166.931503,
    name: "Nauru",
    flag: "🇳🇷",
  },
  {
    key: "NU",
    latitude: -19.054445,
    longitude: -169.867233,
    name: "Niue",
    flag: "🇳🇺",
  },
  {
    key: "NZ",
    latitude: -40.900557,
    longitude: 174.885971,
    name: "New Zealand",
    flag: "🇳🇿",
    customFlag: flags.NZ,
  },
  {
    key: "OM",
    latitude: 21.512583,
    longitude: 55.923255,
    name: "Oman",
    flag: "🇴🇲",
  },
  {
    key: "PA",
    latitude: 8.537981,
    longitude: -80.782127,
    name: "Panama",
    flag: "🇵🇦",
  },
  {
    key: "PE",
    latitude: -9.189967,
    longitude: -75.015152,
    name: "Peru",
    flag: "🇵🇪",
  },
  {
    key: "PF",
    latitude: -17.679742,
    longitude: -149.406843,
    name: "French Polynesia",
    flag: "🇵🇫",
  },
  {
    key: "PG",
    latitude: -6.314993,
    longitude: 143.95555,
    name: "Papua New Guinea",
    flag: "🇵🇬",
  },
  {
    key: "PH",
    latitude: 12.879721,
    longitude: 121.774017,
    name: "Philippines",
    flag: "🇵🇭",
    customFlag: flags.PH,
  },
  {
    key: "PK",
    latitude: 30.375321,
    longitude: 69.345116,
    name: "Pakistan",
    flag: "🇵🇰",
  },
  {
    key: "PL",
    latitude: 51.919438,
    longitude: 19.145136,
    name: "Poland",
    flag: "🇵🇱",
    customFlag: flags.PL,
  },
  {
    key: "PM",
    latitude: 46.941936,
    longitude: -56.27111,
    name: "Saint Pierre and Miquelon",
    flag: "🇵🇲",
  },
  {
    key: "PN",
    latitude: -24.703615,
    longitude: -127.439308,
    name: "Pitcairn Islands",
    flag: "🇵🇳",
  },
  {
    key: "PR",
    latitude: 18.220833,
    longitude: -66.590149,
    name: "Puerto Rico",
    flag: "🇵🇷",
  },
  {
    key: "PS",
    latitude: 31.952162,
    longitude: 35.233154,
    name: "Palestinian Territories",
    flag: "🇵🇸",
  },
  {
    key: "PT",
    latitude: 39.399872,
    longitude: -8.224454,
    name: "Portugal",
    flag: "🇵🇹",
    customFlag: flags.PT,
  },
  {
    key: "PW",
    latitude: 7.51498,
    longitude: 134.58252,
    name: "Palau",
    flag: "🇵🇼",
  },
  {
    key: "PY",
    latitude: -23.442503,
    longitude: -58.443832,
    name: "Paraguay",
    flag: "🇵🇾",
  },
  {
    key: "QA",
    latitude: 25.354826,
    longitude: 51.183884,
    name: "Qatar",
    flag: "🇶🇦",
  },
  {
    key: "RE",
    latitude: -21.115141,
    longitude: 55.536384,
    name: "Réunion",
    flag: "🇷🇪",
  },
  {
    key: "RO",
    latitude: 45.943161,
    longitude: 24.96676,
    name: "Romania",
    flag: "🇷🇴",
    customFlag: flags.RO,
  },
  {
    key: "RS",
    latitude: 44.016521,
    longitude: 21.005859,
    name: "Serbia",
    flag: "🇷🇸",
  },
  {
    key: "RU",
    latitude: 61.52401,
    longitude: 105.318756,
    name: "Russia",
    flag: "🇷🇺",
    customFlag: flags.RU,
  },
  {
    key: "RW",
    latitude: -1.940278,
    longitude: 29.873888,
    name: "Rwanda",
    flag: "🇷🇼",
  },
  {
    key: "SA",
    latitude: 23.885942,
    longitude: 45.079162,
    name: "Saudi Arabia",
    flag: "🇸🇦",
  },
  {
    key: "SB",
    latitude: -9.64571,
    longitude: 160.156194,
    name: "Solomon Islands",
    flag: "🇸🇧",
  },
  {
    key: "SC",
    latitude: -4.679574,
    longitude: 55.491977,
    name: "Seychelles",
    flag: "🇸🇨",
  },
  {
    key: "SD",
    latitude: 12.862807,
    longitude: 30.217636,
    name: "Sudan",
    flag: "🇸🇩",
  },
  {
    key: "SE",
    latitude: 60.128161,
    longitude: 18.643501,
    name: "Sweden",
    flag: "🇸🇪",
    customFlag: flags.SE,
  },
  {
    key: "SG",
    latitude: 1.352083,
    longitude: 103.819836,
    name: "Singapore",
    flag: "🇸🇬",
    customFlag: flags.SG,
  },
  {
    key: "SH",
    latitude: -24.143474,
    longitude: -10.030696,
    name: "Saint Helena",
    flag: "🇸🇭",
  },
  {
    key: "SI",
    latitude: 46.151241,
    longitude: 14.995463,
    name: "Slovenia",
    flag: "🇸🇮",
    customFlag: flags.SI,
  },
  {
    key: "SJ",
    latitude: 77.553604,
    longitude: 23.670272,
    name: "Svalbard and Jan Mayen",
    flag: "🇸🇯",
  },
  {
    key: "SK",
    latitude: 48.669026,
    longitude: 19.699024,
    name: "Slovakia",
    flag: "🇸🇰",
    customFlag: flags.SK,
  },
  {
    key: "SL",
    latitude: 8.460555,
    longitude: -11.779889,
    name: "Sierra Leone",
    flag: "🇸🇱",
  },
  {
    key: "SM",
    latitude: 43.94236,
    longitude: 12.457777,
    name: "San Marino",
    flag: "🇸🇲",
  },
  {
    key: "SN",
    latitude: 14.497401,
    longitude: -14.452362,
    name: "Senegal",
    flag: "🇸🇳",
  },
  {
    key: "SO",
    latitude: 5.152149,
    longitude: 46.199616,
    name: "Somalia",
    flag: "🇸🇴",
  },
  {
    key: "SR",
    latitude: 3.919305,
    longitude: -56.027783,
    name: "Suriname",
    flag: "🇸🇷",
  },
  {
    key: "SS",
    latitude: 6.877,
    longitude: 31.307,
    name: "South Sudan",
    flag: "🇸🇸",
  },
  {
    key: "ST",
    latitude: 0.18636,
    longitude: 6.613081,
    name: "São Tomé and Príncipe",
    flag: "🇸🇹",
  },
  {
    key: "SV",
    latitude: 13.794185,
    longitude: -88.89653,
    name: "El Salvador",
    flag: "🇸🇻",
  },
  {
    key: "SX",
    latitude: 18.034718,
    longitude: -63.068977,
    name: "Sint Maarten",
    flag: "🇸🇽",
  },
  {
    key: "SY",
    latitude: 34.802075,
    longitude: 38.996815,
    name: "Syria",
    flag: "🇸🇾",
  },
  {
    key: "SZ",
    latitude: -26.522503,
    longitude: 31.465866,
    name: "Eswatini",
    flag: "🇸🇿",
  },
  {
    key: "TC",
    latitude: 21.694025,
    longitude: -71.797928,
    name: "Turks and Caicos Islands",
    flag: "🇹🇨",
  },
  {
    key: "TD",
    latitude: 15.454166,
    longitude: 18.732207,
    name: "Chad",
    flag: "🇹🇩",
  },
  {
    key: "TF",
    latitude: -49.280366,
    longitude: 69.348557,
    name: "French Southern Territories",
    flag: "🇹🇫",
  },
  {
    key: "TG",
    latitude: 8.619543,
    longitude: 0.824782,
    name: "Togo",
    flag: "🇹🇬",
  },
  {
    key: "TH",
    latitude: 15.870032,
    longitude: 100.992541,
    name: "Thailand",
    flag: "🇹🇭",
    customFlag: flags.TH,
  },
  {
    key: "TJ",
    latitude: 38.861034,
    longitude: 71.276093,
    name: "Tajikistan",
    flag: "🇹🇯",
  },
  {
    key: "TK",
    latitude: -8.967363,
    longitude: -171.855881,
    name: "Tokelau",
    flag: "🇹🇰",
  },
  {
    key: "TL",
    latitude: -8.874217,
    longitude: 125.727539,
    name: "Timor-Leste",
    flag: "🇹🇱",
  },
  {
    key: "TM",
    latitude: 38.969719,
    longitude: 59.556278,
    name: "Turkmenistan",
    flag: "🇹🇲",
  },
  {
    key: "TN",
    latitude: 33.886917,
    longitude: 9.537499,
    name: "Tunisia",
    flag: "🇹🇳",
  },
  {
    key: "TO",
    latitude: -21.178986,
    longitude: -175.198242,
    name: "Tonga",
    flag: "🇹🇴",
  },
  {
    key: "TR",
    latitude: 38.963745,
    longitude: 35.243322,
    name: "Turkey",
    flag: "🇹🇷",
    customFlag: flags.TR,
  },
  {
    key: "TT",
    latitude: 10.691803,
    longitude: -61.222503,
    name: "Trinidad and Tobago",
    flag: "🇹🇹",
  },
  {
    key: "TV",
    latitude: -7.109535,
    longitude: 177.64933,
    name: "Tuvalu",
    flag: "🇹🇻",
  },
  {
    key: "TW",
    latitude: 23.69781,
    longitude: 120.960515,
    name: "Taiwan",
    flag: "🇹🇼",
    customFlag: flags.TW,
  },
  {
    key: "TZ",
    latitude: -6.369028,
    longitude: 34.888822,
    name: "Tanzania",
    flag: "🇹🇿",
  },
  {
    key: "UA",
    latitude: 48.379433,
    longitude: 31.16558,
    name: "Ukraine",
    flag: "🇺🇦",
  },
  {
    key: "UG",
    latitude: 1.373333,
    longitude: 32.290275,
    name: "Uganda",
    flag: "🇺🇬",
  },
  {
    key: "US",

    latitude: 37.09024,
    longitude: -95.712891,
    name: "United States",
    flag: "🇺🇸",
    customFlag: flags.US,
  },
  {
    key: "UY",
    latitude: -32.522779,
    longitude: -55.765835,
    name: "Uruguay",
    flag: "🇺🇾",
  },
  {
    key: "UZ",
    latitude: 41.377491,
    longitude: 64.585262,
    name: "Uzbekistan",
    flag: "🇺🇿",
  },
  {
    key: "VA",
    latitude: 41.902916,
    longitude: 12.453389,
    name: "Vatican City",
    flag: "🇻🇦",
  },
  {
    key: "VC",
    latitude: 12.984305,
    longitude: -61.287228,
    name: "Saint Vincent and the Grenadines",
    flag: "🇻🇨",
  },
  {
    key: "VE",
    latitude: 6.42375,
    longitude: -66.58973,
    name: "Venezuela",
    flag: "🇻🇪",
  },
  {
    key: "VG",
    latitude: 18.420695,
    longitude: -64.639968,
    name: "British Virgin Islands",
    flag: "🇻🇬",
  },
  {
    key: "VI",
    latitude: 18.335765,
    longitude: -64.896335,
    name: "U.S. Virgin Islands",
    flag: "🇻🇮",
  },
  {
    key: "VN",
    latitude: 14.058324,
    longitude: 108.277199,
    name: "Vietnam",
    flag: "🇻🇳",
    customFlag: flags.VN,
  },
  {
    key: "VU",
    latitude: -15.376706,
    longitude: 166.959158,
    name: "Vanuatu",
    flag: "🇻🇺",
  },
  {
    key: "WF",
    latitude: -13.768752,
    longitude: -177.156097,
    name: "Wallis and Futuna",
    flag: "🇼🇫",
  },
  {
    key: "WS",
    latitude: -13.759029,
    longitude: -172.104629,
    name: "Samoa",
    flag: "🇼🇸",
  },
  {
    key: "XK",
    latitude: 42.602636,
    longitude: 20.902977,
    name: "Kosovo",
    flag: "🇽🇰",
  },
  {
    key: "YE",
    latitude: 15.552727,
    longitude: 48.516388,
    name: "Yemen",
    flag: "🇾🇪",
  },
  {
    key: "YT",
    latitude: -12.8275,
    longitude: 45.166244,
    name: "Mayotte",
    flag: "🇾🇹",
  },
  {
    key: "ZA",
    latitude: -30.559482,
    longitude: 22.937506,
    name: "South Africa",
    flag: "🇿🇦",
  },
  {
    key: "ZM",
    latitude: -13.133897,
    longitude: 27.849332,
    name: "Zambia",
    flag: "🇿🇲",
  },
  {
    key: "ZW",
    latitude: -19.015438,
    longitude: 29.154857,
    name: "Zimbabwe",
    flag: "🇿🇼",
  },
]
