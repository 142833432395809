import { icons } from "assets/icons"
import { images } from "assets/images"
import { InfoBar } from "components"
import { cx } from "styles"

import { Globe } from "../../components/Globe"

import styles from "./Regions.module.scss"

interface RegionsProps {
  className?: string
}

const INFO_BAR_BORDER =
  "linear-gradient(91.06deg, #001aff 10.63%, #00ddd0 132.15%),linear-gradient(0deg, #151726, #151726)"

export const Regions = ({ className }: RegionsProps) => {
  return (
    <div className={cx(styles.root, className)}>
      <Globe />

      <div className={styles.info}>
        <InfoBar
          backgroundImage={`url(${images.regionBarBg})`}
          borderGradient={INFO_BAR_BORDER}
          className={styles.infoBar}
          leftContent={{
            imageSrc: icons.glob,
            text: <InfoBarText />,
          }}
        />
      </div>
    </div>
  )
}

const InfoBarText = () => (
  <span className={styles.infoText}>
    <b>Kodai is for everyone!</b> With support for over{" "}
    <span className={styles.infoTextUnderline}>50</span> different regions!
  </span>
)
