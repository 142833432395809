import {
  ChangeEvent,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  Ref,
} from "react"
import { cx } from "styles"

import { ErrorMessage } from "../ErrorMessage"

import styles from "./Input.module.scss"
export type InputSize = "medium" | "large"

export type InputProps = {
  id?: string
  label?: string
  size?: InputSize
  className?: string
  error?: string
  errorAlign?: "left" | "center"
  onChange: (text: ChangeEvent<HTMLInputElement>) => void
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "size" | "onChange"
>

export const Input = forwardRef(
  (
    {
      disabled,
      autoFocus,
      autoSave,
      onKeyDown,
      className,
      size = "medium",
      error,
      errorAlign,
      ...rest
    }: InputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const hasError = !!error

    return (
      <>
        <div
          className={cx(styles.inputContainer, {
            [styles.error]: hasError,
            [styles.disabled]: disabled,
          })}>
          <input
            {...rest}
            autoFocus={autoFocus}
            autoSave={autoSave}
            className={cx(styles.input, className, styles[size])}
            disabled={disabled}
            ref={ref}
            onKeyDown={(e) => {
              if (onKeyDown) {
                onKeyDown(e)
              }
              if (e.key === "Enter") {
                rest.onSubmit?.(e)
              }
            }}
          />
        </div>
        <ErrorMessage
          align={errorAlign}
          className={styles.errorMessage}
          shown={!!error}>
          {error}
        </ErrorMessage>
      </>
    )
  },
)
