import { PaymentPage } from "modules"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom"

import { AppLayout } from "./AppLayout"
import { routes } from "./routes"

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<AppLayout />}>
        <Route {...routes.PAYMENT}>
          <Route element={<PaymentPage />} {...routes.PAYMENT} />
        </Route>
      </Route>
    </>,
  ),
)
