import { icons } from "assets/icons"
import { useMediaQuery } from "hooks"
import { useState } from "react"
import { useMeasure } from "react-use"
import { cx } from "styles"
import { isTouchableDevice } from "utils/getDeviceType"

import styles from "./Button.module.scss"
import { Stars } from "./Stars"

export interface ButtonProps {
  className?: string
  classNameButton?: string
  classNameContent?: string
  children: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
  variant?: "primary" | "fill"
  size?: "medium" | "large"
  type?: "button" | "submit" | "reset"
}

export const Button = ({
  className,
  children,
  onClick,
  disabled,
  loading,
  classNameButton,
  size = "medium",
  classNameContent,
  type,
  variant = "primary",
}: ButtonProps) => {
  const isMobileQuery = useMediaQuery("mobile")
  const isHoverDisabled = isMobileQuery || isTouchableDevice()

  const [hover, setHover] = useState(false)
  const [containerRef, { width, height }] = useMeasure()

  return (
    <div
      className={cx(styles.root, className)}
      ref={(r) => {
        if (r) {
          containerRef(r)
        }
      }}>
      <Stars height={height} hover={!isHoverDisabled && hover} width={width} />
      <button
        className={cx(
          styles.button,
          classNameButton,
          styles[size],
          styles[variant],
          {
            [styles.hover]: !isHoverDisabled && (hover || !!loading),
            [styles.disabled]: disabled,
            [styles.loading]: loading,
          },
        )}
        disabled={disabled || loading}
        type={type}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <img
          alt="loading-logo"
          className={styles.loadingLogo}
          draggable={false}
          src={variant === "fill" ? icons.logoSecondary : icons.logo}
        />
        <div className={cx(styles.content, classNameContent)}>{children}</div>
        {/* the background property does not support transitions */}
        <div className={styles.buttonBackground} />
      </button>
    </div>
  )
}
