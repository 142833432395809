import { useCallback, useEffect, useRef } from "react"

export const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null)

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    },
    [callback],
  )

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => handleClick(event)
    document.addEventListener("click", handleOutsideClick)

    return () => {
      document.removeEventListener("click", handleOutsideClick)
    }
  }, [handleClick])

  return { ref }
}
