import { safeJsonParse } from "utils/safeJsonParse"

interface Data {
  time: number
}

export const persistedStore = {
  getItem: <Key extends keyof Data>(key: Key): Data[Key] | null => {
    const storageData = sessionStorage.getItem(key)
    const parsedData = safeJsonParse<Data[Key]>(storageData)
    return parsedData
  },
  setItem: <Key extends keyof Data>({
    data,
    key,
  }: {
    data: Data[Key]
    key: Key
  }) => {
    sessionStorage.setItem(key, JSON.stringify(data))
  },
  clearItem: (key: keyof Data) => {
    sessionStorage.removeItem(key)
  },
}
