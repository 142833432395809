import { cx } from "styles"

import styles from "./PaymentLoading.module.scss"

const loadingTextArray = [
  "[0%] CONNECTING TO KODAI...",
  "[25%] VERIFYING ELIGIBLE TO PURCHASE...",
  "[50%] RESERVING LICENSE KEY...",
  "[100%] PROCESSING TRANSACTION...",
]
interface PaymentLoadingProps {
  isShown: boolean
}

export const PaymentLoading = ({ isShown }: PaymentLoadingProps) => {
  return (
    <div className={cx(styles.root, { [styles.shown]: isShown })}>
      {loadingTextArray.map((el, index) => (
        <div className={styles.element} key={index}>
          {el}
        </div>
      ))}
    </div>
  )
}
