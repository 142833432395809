import { errorName, stripeErrorName } from "api/constants"

import { ERROR_TEXT } from "./validation"

const errorMessages = {
  [errorName.cardDeclined]: "Your card was declined.",
  [errorName.expiredCard]: "Your card is expired.",
  [errorName.incorrectNumber]: "Your card number may be incorrect.",
  [errorName.processingError]:
    "We encountered an error processing the transaction. Please try again.",
  [errorName.incorrectCvc]: "Your security code may be incorrect.",
  [stripeErrorName.invalidCvc]: "Your security code may be incorrect.",
  [errorName.cvcCheck]: "Check your security code.",
  [errorName.resourceMissing]:
    "We couldn't process the transaction. Please try again.",
  [errorName.invalidEmail]: "Please enter a valid email address.",
}

export const purchaseErrorMessage = (errorName: string) => {
  return errorMessages[errorName] || ERROR_TEXT.default
}
