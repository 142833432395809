import CHECKING_ANIMATION from "./availability_animation.mp4"
import CHECKING_ANIMATION_MOBILE from "./availability_animation_mobile.mp4"
import TAB_REGIONS from "./regions.mp4"
import TAB_SITELIST from "./sitelist.mp4"
import TAB_SOCIALS from "./socials.mp4"
import TAB_SUCCESS from "./success.mp4"

export const video = {
  checkingAnimation: CHECKING_ANIMATION,
  checkingAnimationMobile: CHECKING_ANIMATION_MOBILE,
  tabRegions: TAB_REGIONS,
  tabSocials: TAB_SOCIALS,
  tabSuccess: TAB_SUCCESS,
  tabSiteList: TAB_SITELIST,
}
