import * as yup from "yup"
import type { Schema, InferType, ObjectSchema, AnyObject } from "yup"

import { ERROR_TEXT } from "./constants"

yup.setLocale({
  mixed: {
    required: "Field is required",
  },
})

export const string = () => yup.string()
export const email = () => yup.string().email(ERROR_TEXT.invalidEmail)

export const formatNumericInput = (text: string) => {
  return text.replace(/[^0-9]/g, "")
}

//export from yup
export { object, mixed } from "yup"
export type { Schema, InferType, ObjectSchema, AnyObject }
export * from "./constants"
