import { cx } from "styles"

import styles from "./Layout.module.scss"

interface LayoutProps {
  className?: string
  children: React.ReactNode
}

export const Layout = ({ className, children }: LayoutProps) => (
  <div className={cx(styles.root, className)}>{children}</div>
)
