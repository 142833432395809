import ModalBase from "react-modal"
import { cx } from "styles"

import styles from "./Modal.module.scss"

interface ModalProps {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
  title?: string
  description?: string
  className?: string
}

ModalBase.setAppElement("#root")

export const Modal = ({
  children,
  isOpen,
  onClose,
  description,
  title,
  className,
}: ModalProps) => {
  return (
    <ModalBase
      className={cx(styles.modal, className)}
      closeTimeoutMS={200}
      contentLabel="modal"
      isOpen={isOpen}
      overlayClassName={styles.overlay}
      onRequestClose={onClose}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalContent}>
          {title && <div className={styles.title}>{title}</div>}
          {description && (
            <div className={styles.description}>{description}</div>
          )}
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </ModalBase>
  )
}
