export const supportedRegions = [
  "au",
  "at",
  "be",
  "br",
  "bg",
  "ca",
  "cn",
  "cz",
  "dk",
  "fi",
  "fr",
  "de",
  "gr",
  "hk",
  "hu",
  "in",
  "id",
  "ie",
  "il",
  "it",
  "jp",
  "lt",
  "lu",
  "mo",
  "my",
  "mx",
  "nl",
  "nz",
  "no",
  "ph",
  "pl",
  "pt",
  "ro",
  "ru",
  "sg",
  "sk",
  "si",
  "kr",
  "es",
  "se",
  "ch",
  "tw",
  "th",
  "tr",
  "uk",
  "us",
]
