export const stripeErrorName = {
  // Card Errors
  invalidCardNumber: "invalid_card_number",
  invalidExpiryDate: "invalid_expiry_date",
  invalidCvc: "invalid_cvc",
  expiredCard: "expired_card",
  incorrectCvc: "incorrect_cvc",
  cardDeclined: "card_declined",

  // API Errors
  apiError: "api_error",
  authenticationError: "authentication_error",
  rateLimitError: "rate_limit_error",
  invalidRequestError: "invalid_request_error",
  permissionError: "permission_error",
  notFoundError: "not_found_error",
  idempotencyError: "idempotency_error",

  // Other Common Errors
  unknown: "unknown_error",
  validationError: "validation_error",
  processingError: "processing_error",
}

export const errorName = {
  auth: "AuthError",
  unavailable: "UnavailableError",
  notReady: "NotReadyError",
  rateLimited: "RateLimitedError",
  communication: "CommunicationError",
  cardDeclined: "card_declined",
  expiredCard: "expired_card",
  incorrectNumber: "incorrect_number",
  processingError: "processing_error",
  incorrectCvc: "incorrect_cvc",
  cvcCheck: "cvc_check",
  resourceMissing: "resource_missing",
  invalidEmail: "invalid_email",
  unknown: "unknown",
}
