export const ERROR_TEXT = {
  invalidEmail: "Please enter a valid email address",
  requiredEmail: "Please enter your email address",
  requiredCardNumber: "Please enter your card number",
  invalidCardNumber: "Invalid card number",
  invalidCVCCode: "Please enter the correct CVC code",
  requiredCVC: "Please enter the CVC code",
  requiredExpDate: "Please enter the expiration date",
  min: (num: number) => `Please use at least ${num} characters`,
  default: "An unexpected error occurred. Please try again.",
}
