import { icons } from "assets/icons"

import { formatTime } from "../../utils"

import styles from "./Timer.module.scss"

const expiredText = `expires in`

interface TimerProps {
  expiredTime: number | null
}

export const Timer = ({ expiredTime }: TimerProps) => (
  <div className={styles.root}>
    <span className={styles.text}>
      {expiredText.toUpperCase()} {formatTime(expiredTime || 0)}
    </span>
    <img className={styles.separator} draggable={false} src={icons.star} />
  </div>
)
