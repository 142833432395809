import { CSSProperties, ReactNode } from "react"
import Tilt, { ReactParallaxTiltProps } from "react-parallax-tilt"
import { cx } from "styles"
import { isTouchableDevice } from "utils/getDeviceType"

import styles from "./ParallacticTiltCard.module.scss"

interface ParallacticTiltCardProps extends ReactParallaxTiltProps {
  className?: string
  children: ReactNode
  style?: CSSProperties
}

export const ParallacticTiltCard = ({
  className,
  children,
  style,
  ...rest
}: ParallacticTiltCardProps) => {
  const isDisabled = isTouchableDevice() || !rest.tiltEnable

  return (
    <Tilt
      {...rest}
      className={cx(styles.root, className)}
      glarePosition="all"
      gyroscope={false}
      style={{
        ...style,
        pointerEvents: isDisabled ? "none" : "initial",
      }}>
      {children}
    </Tilt>
  )
}
