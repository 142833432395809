import { icons } from "assets/icons"
import { cx } from "styles"

import styles from "./Features.module.scss"
import { FeaturesDesktop } from "./components/FeaturesDesktop"
import { FeaturesMobile } from "./components/FeaturesMobile"

export interface FeatureType {
  features: string[]
  title: string
}

interface FeaturesProps {
  className?: string
  items: FeatureType[] | null
  additionalListId: number | null
  setAdditionalListId: (id: number | null) => void
}

export const Features = ({
  className,
  items,
  additionalListId,
  setAdditionalListId,
}: FeaturesProps) => {
  // display only 3 main features
  const featureItems = items ? items.slice(0, 3) : null
  // display only 3 icons
  const featureIcons = [icons.newRelease, icons.lightning, icons.box].slice(
    0,
    3,
  )

  return (
    <div className={styles.root} onClick={(e) => e.stopPropagation()}>
      <FeaturesDesktop
        additionalListId={additionalListId}
        className={cx(styles.desktop, className)}
        headerIcons={featureIcons}
        items={featureItems}
        setAdditionalListId={setAdditionalListId}
      />
      <FeaturesMobile
        additionalListId={additionalListId}
        className={cx(styles.mobile, className)}
        headerIcons={featureIcons}
        items={featureItems}
        setAdditionalListId={setAdditionalListId}
      />
    </div>
  )
}
