import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { Button, Input } from "components"
import { ErrorMessage } from "components/ErrorMessage"
import { envConfig } from "config"
import { useMediaQuery } from "hooks"
import { ERROR_TEXT, persistedStore } from "lib"
import { usePurchase } from "providers/purchase/usePurchase"
import { ChangeEvent, useState } from "react"
import { cx } from "styles"

import styles from "./PaymentCardForm.module.scss"
import { cardStyles, validationSchema } from "./utils"

const SUBSCRIPTION_DURATION = 1
const LICENSE_KEY = envConfig.LICENSE_KEY
export const PRICE = 74.95 // $

interface PaymentCardFormProps {
  className?: string
}

export const PaymentCardForm = ({ className }: PaymentCardFormProps) => {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState<string>()
  const [error, setError] = useState<string | null>(null)
  const [isValidationProcess, setValidationProcess] = useState(false)

  const { mutateAsync } = usePurchase()
  const stripe = useStripe()
  const elements = useElements()

  const isTablet = useMediaQuery("tablet")

  const onChangeValidationState = (v: boolean, delay?: boolean) => {
    setTimeout(() => setValidationProcess(v), delay ? 1000 : 0)
  }

  const checkEmailValidation = async () => {
    try {
      await validationSchema.validate({ email })
      setEmailError("")

      return { success: true, error: "" }
    } catch (e) {
      if (e instanceof Error) {
        setEmailError(e.message)

        if (!!e.message || !email) {
          onChangeValidationState(false)
        }

        return { success: false, error: e.message }
      }

      return { success: false, error: ERROR_TEXT.default }
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setValidationProcess(true)
    // validate email
    const isFormValid = await checkEmailValidation()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet, or elements are not yet available
      return
    }

    const cardElement = elements.getElement(CardElement)

    if (!cardElement || !LICENSE_KEY) {
      // CardElement is not found
      setError(ERROR_TEXT.default)
      return
    }

    const { error, token } = await stripe.createToken(cardElement)

    if (error || !!isFormValid.error || !email) {
      if (!!isFormValid.error || !email) onChangeValidationState(false)

      if (error) {
        onChangeValidationState(false)

        if (error.message?.endsWith(".")) {
          setError(error.message.replace(/\.$/, "") || null)

          return
        }

        setError(error.message || null)
      }
      return
    }

    await mutateAsync({
      subscription_duration: SUBSCRIPTION_DURATION,
      license_key: LICENSE_KEY,
      amount: PRICE,
      payment_info: {
        contact_email: email,
        token: token.id,
      },
    }).then(() => {
      onChangeValidationState(false, true)
      persistedStore.clearItem("time")
    })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailError("")
    setEmail(e.target.value)
  }

  return (
    <>
      <form className={cx(styles.root, className)} onSubmit={handleSubmit}>
        <div>
          <Input
            className={styles.emailInput}
            error={emailError}
            errorAlign="center"
            name="email"
            placeholder="Email"
            type="email"
            value={email}
            onChange={handleChange}
          />
          <div className={styles.divider} />
          <div
            className={cx(styles.cardInputContainer, {
              [styles.error]: !!error,
            })}>
            <div className={styles.input}>
              <CardElement
                options={{
                  classes: {
                    base: styles.cardBase,
                    invalid: styles.cardInvalid,
                  },
                  hidePostalCode: true,
                  hideIcon: true,
                  style: {
                    invalid: {
                      color: "#fff",
                    },
                    base: cardStyles,
                  },
                }}
                onChange={() => setError(null)}
                onFocus={() => setError(null)}
              />
            </div>
          </div>
          <ErrorMessage align="center" shown={!!error}>
            {error}
          </ErrorMessage>
          <Button
            className={styles.button}
            disabled={isValidationProcess}
            loading={isValidationProcess}
            type="submit"
            variant={!isTablet ? "primary" : "fill"}>
            Purchase Kodai
          </Button>
        </div>
      </form>
    </>
  )
}
