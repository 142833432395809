import { Button, Confetti, Modal } from "components"
import { useRef } from "react"
import { useWindowSize } from "react-use"

import styles from "./AvailabilitySuccess.module.scss"

interface AvailabilitySuccessProps {
  isStarted: boolean
  onFinish: () => void
}

const ADDITIONAL_HEIGHT = 500

export const AvailabilitySuccess = ({
  isStarted,
  onFinish,
}: AvailabilitySuccessProps) => {
  const { width, height } = useWindowSize()
  const refLeft = useRef<HTMLCanvasElement>(null)
  const refRight = useRef<HTMLCanvasElement>(null)

  if (!isStarted) {
    return <></>
  }

  const confettiProps = {
    width: width,
    height: height,
    numberOfPieces: 600,
    recycle: false,
    tweenDuration: 2000,
  }

  // make the explosion higher
  const y = height - ADDITIONAL_HEIGHT
  const confettiFlowWidth = 20

  return (
    <div className={styles.root}>
      {isStarted && (
        <>
          {/* left */}
          <Confetti
            {...confettiProps}
            confettiSource={{
              // left position
              x: 0,
              y,
              h: height,
              w: confettiFlowWidth,
            }}
            initialVelocityX={{ min: 0, max: 15 }}
            initialVelocityY={{ min: 0, max: -20 }}
            ref={refLeft}
          />
          {/* right */}
          <Confetti
            {...confettiProps}
            confettiSource={{
              // right position
              x: width,
              y,
              h: height,
              w: confettiFlowWidth,
            }}
            initialVelocityX={{ min: -15, max: 0 }}
            initialVelocityY={{ min: -20, max: 0 }}
            ref={refRight}
            width={width}
          />
        </>
      )}

      <Modal
        className={styles.modal}
        description={`We have reserved a checkout for you. You now have 20 minutes to checkout.\n\nCheckout fast! Once the time runs out we cannot guarantee you will be able to purchase Kodai.`}
        isOpen={isStarted}
        title={`Lucky you! You\ngot access to\npurchase Kodai!`}
        onClose={onFinish}>
        <div className={styles.modalButtonContainer}>
          <Button classNameButton={styles.modalButton} onClick={onFinish}>
            <div>Continue</div>
          </Button>
        </div>
      </Modal>
    </div>
  )
}
