import isNull from "lodash-es/isNull"
import { useEffect, useState } from "react"

interface TimerProps {
  initialTime: number | null
  onAvailable: () => void
  isPurchaseSuccess: boolean
}

export const useTimer = ({
  initialTime,
  onAvailable,
  isPurchaseSuccess,
}: TimerProps) => {
  const [expiredTime, setExpiredTime] = useState<number | null>(initialTime)
  const isTimeExpired = isNull(expiredTime)

  const onChangeExpiredTime = () => {
    setExpiredTime((prevTime) => (prevTime ? prevTime - 1 : 0))
  }

  useEffect(() => {
    let timer: NodeJS.Timer

    // stop the timer when switching to another tab
    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearInterval(timer)
      } else {
        timer = setInterval(onChangeExpiredTime, 1000)
      }
    }

    if (!isTimeExpired) {
      timer = setInterval(onChangeExpiredTime, 1000)

      if (expiredTime === 0) {
        clearInterval(timer)
      }

      if (isPurchaseSuccess) {
        onAvailable()
        setExpiredTime(null)
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      clearInterval(timer)
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [expiredTime, isPurchaseSuccess, isTimeExpired, onAvailable])

  return {
    expiredTime,
    isPurchaseSuccess,
    setExpiredTime,
  }
}
