import { productCardsImages } from "assets/images"
import chunk from "lodash-es/chunk"
import { CardColor } from "types/productCards"
import { repeatAndFlatArray } from "utils/repeatArray"
import { shuffleArray } from "utils/shuffleArray"

const REPEAT_COUNT = 4

// 6 card repeats
const CARD_COLORS_ARR: CardColor[] = repeatAndFlatArray(
  ["orange", "green", "blue", "orange"],
  1,
)

const CARDS_COUNT = Object.keys(productCardsImages).length - 1
const CARDS_LENGTH = CARDS_COUNT >= 12 ? 12 : CARDS_COUNT
const shuffledCardsIds = shuffleArray(
  Array.from({ length: CARDS_COUNT }, (_, i) => i + 1),
).slice(0, CARDS_LENGTH)

const splittedCardIds = chunk(shuffledCardsIds, CARDS_LENGTH / 3)
const [cardIds1, cardIds2, cardIds3] = splittedCardIds

// shuffled scrolling product cards
const repeatColors = () =>
  repeatAndFlatArray(shuffleArray(CARD_COLORS_ARR), REPEAT_COUNT)

export const CARD_COLORS = [repeatColors(), repeatColors(), repeatColors()]

export const CARD_IDS = [
  cardIds1.concat(cardIds2, cardIds1, cardIds3),
  cardIds2.concat(cardIds3, cardIds2, cardIds1),
  cardIds3.concat(cardIds1, cardIds3, cardIds2),
]
