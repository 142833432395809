export const repeatArray = <T>(arr: T[], repeatCount: number): T[][] => {
  let n = 0
  const result: T[][] = []
  while (n < repeatCount) {
    n++
    result.push(arr)
  }

  return result
}

export const repeatAndFlatArray = <T>(arr: T[], repeatCount: number): T[] => {
  return repeatArray(arr, repeatCount).flat()
}
