import { AnimatePresence, motion } from "framer-motion"
import { ReactNode } from "react"
import { cx } from "styles"

import styles from "./ErrorMessage.module.scss"

const errorMessageVariants = {
  in: {
    height: "auto",
    opacity: 1,
  },
  out: {
    height: 0,
    opacity: 0,
  },
}

interface ErrorMessageProps {
  className?: string
  absolute?: boolean
  shown?: boolean
  align?: "left" | "center"
  children?: ReactNode
}

export const ErrorMessage = ({
  className,
  shown,
  align = "left",
  absolute,
  ...rest
}: ErrorMessageProps) => {
  return (
    <AnimatePresence initial={false}>
      {shown && (
        <motion.div
          animate={errorMessageVariants.in}
          className={cx(styles.errorMessage, styles[align], className, {
            [styles.absolute]: absolute,
          })}
          exit={errorMessageVariants.out}
          initial={errorMessageVariants.out}
          transition={{ duration: 0.3 }}>
          <p {...rest} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}
