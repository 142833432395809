export interface Route<T = {}> {
  path: string
  exact?: boolean
  params?: T
  caseSensitive?: boolean
  id?: string
  hasErrorBoundary?: boolean
  element?: React.ReactNode | null
  errorElement?: React.ReactNode | null
}

export interface Path {
  pathname: string
  search: string
  hash: string
}

export enum Routes {
  PAYMENT = "PAYMENT",
  APP = "APP",
}

export const routes: Record<Routes, Route> = {
  [Routes.PAYMENT]: {
    path: "/",
  },
  [Routes.APP]: {
    path: "/app",
  },
}
export const routesPath = Object.values(routes).map((p) => ({ path: p.path }))
