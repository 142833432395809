export const useGenericLink = (href: string) => {
  return {
    role: "link",
    onClick: () => {
      const win = window.open(href)
      if (win) win.focus()
      else window.location.href = href
    },
  }
}
