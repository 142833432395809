import { useEffect, useState } from "react"

interface Size {
  width: number
  height: number
}

export const useResize = (ref: React.RefObject<HTMLElement>): Size => {
  const [elementSize, setElementSize] = useState<Size>({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        const { width, height } = ref.current.getBoundingClientRect()
        setElementSize({ width, height })
      }
    }

    handleResize()

    if (ref.current) {
      window.addEventListener("resize", handleResize)
    }

    return () => {
      if (ref.current) {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [ref])

  return elementSize
}
