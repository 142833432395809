import { cx } from "styles"
import { NavigationTab } from "types/tabs"

import styles from "./TabbedSection.module.scss"
import { Regions, ScrollableProductCards, SiteList, Tabs } from "./sections"
import { CARD_COLORS, CARD_IDS } from "./utils"

interface TabbedSectionProps {
  className?: string
  activeTab: NavigationTab | null
  onTabChange: (v: NavigationTab) => void
}

export const TabbedSection = ({
  className,
  activeTab,
  onTabChange,
}: TabbedSectionProps) => {
  return (
    <div
      className={cx(styles.root, className, {
        [styles.regionsContent]: activeTab === NavigationTab.REGIONS,
        [styles.productCardsContent]: activeTab === NavigationTab.SUCCESS,
      })}>
      {activeTab && (
        <Tabs
          activeTab={activeTab}
          className={cx(styles.tabs)}
          onTabChange={onTabChange}
        />
      )}

      <div className={styles.content}>
        <ScrollableProductCards
          cardColors={CARD_COLORS}
          cardsIds={CARD_IDS}
          className={cx({
            [styles.hiddenTab]: activeTab !== NavigationTab.SUCCESS,
          })}
        />
        {activeTab === NavigationTab.SITELIST && <SiteList />}
        <Regions
          className={cx({
            [styles.hiddenTab]: activeTab !== NavigationTab.REGIONS,
          })}
        />
      </div>
    </div>
  )
}
