import { useEffect } from "react"
import { NavigationTab } from "types/tabs"

import { tabsList } from "./tabsList"

const navigationTabs = Object.values(NavigationTab)

interface VideoPlayerProps {
  activeTab: NavigationTab
  tab: NavigationTab
  videoRef: React.MutableRefObject<{
    [key in NavigationTab]?: HTMLVideoElement | undefined
  }>
  className: string
  hoveredTab: NavigationTab | null
}

export const VideoPlayer = ({
  activeTab,
  videoRef,
  tab,
  className,
  hoveredTab,
}: VideoPlayerProps) => {
  useEffect(() => {
    const playVideo = (tab: NavigationTab) => {
      if (videoRef.current[tab]?.paused) {
        videoRef.current[tab]?.play()
      }
    }

    const pauseVideo = (tab: NavigationTab) => {
      if (!videoRef.current[tab]?.paused) {
        videoRef.current[tab]?.pause()
      }
    }

    navigationTabs.forEach((tab) => {
      if (activeTab === tab || hoveredTab === tab) {
        playVideo(tab)
      } else {
        pauseVideo(tab)
      }
    })
  }, [activeTab, videoRef, hoveredTab])

  return (
    <video
      loop
      muted
      playsInline
      className={className}
      preload="auto"
      ref={(el) => {
        if (el && videoRef.current) {
          videoRef.current[tab] = el
        }
      }}>
      <source src={`${tabsList[tab].video}#t=0.001`} type="video/mp4" />
    </video>
  )
}
