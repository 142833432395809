import { useEffect } from "react"
import { isIOS } from "utils/getDeviceType"

export const usePageUnload = (handleUnload: () => void) => {
  useEffect(() => {
    // beforeunload doesn't work for iOS
    const eventName = isIOS ? "pagehide" : "beforeunload"

    window.addEventListener(eventName, handleUnload)

    return () => {
      window.removeEventListener(eventName, handleUnload)
    }
  }, [handleUnload])
}
