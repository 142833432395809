import { useState } from "react"
import { cx } from "styles"

import { Features } from "../../components/Features"
import { Sites } from "../../components/Sites/Sites"
import { SITES, SiteType } from "../../sitesInfo"

import styles from "./SiteList.module.scss"

interface SiteListProps {
  className?: string
}

export const SiteList = ({ className }: SiteListProps) => {
  const [clickedSite, setClickedSite] = useState<SiteType | null>(null)
  const [additionalListId, setAdditionalListId] = useState<number | null>(null)
  const features = clickedSite ? SITES[clickedSite].features : null

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.sites}>
        <Sites
          onClickSite={(val) => [
            setClickedSite(val),
            setAdditionalListId(null),
          ]}
          onReset={() => setAdditionalListId(null)}
        />
      </div>

      <div
        className={cx(styles.featuresContainer, {
          [styles.hidden]: !clickedSite,
        })}>
        <Features
          additionalListId={additionalListId}
          items={features}
          setAdditionalListId={setAdditionalListId}
        />
      </div>
    </div>
  )
}
