import { useLayoutStyles, useMediaQuery, useModal } from "hooks"
import { persistedStore } from "lib"
import { usePurchase } from "providers/purchase/usePurchase"
import {
  CSSProperties,
  ReactNode,
  forwardRef,
  useEffect,
  useState,
} from "react"
import { cx } from "styles"
import { NavigationTab } from "types/tabs"

import styles from "./PaymentPage.module.scss"
import { Loading as InitialLoading } from "./components/Loading"
import {
  AvailabilityLoading,
  AvailabilitySuccess,
} from "./components/availability"
import {
  PaymentError,
  PaymentLoading,
  PaymentSuccess,
} from "./components/payment"
import { AvailabilityForm, PaymentForm, TabbedSection } from "./features"

const EXPIRATION_TIME = 20 * 60 // 20 min

export const PaymentPage = () => {
  const { isOpen: isSuccessModalOpen, openModal, closeModal } = useModal(false)
  const [minimizedMobileHeader, setMinimizedMobileHeader] = useState(true)
  const [availabilityLoading, setAvailabilityLoading] = useState(false)

  const [initialTime, setInitialTime] = useState<number | null>(
    persistedStore.getItem("time") || null,
  )
  const [isAvailable, setIsAvailable] = useState(!!initialTime)

  const {
    isLoading: isPurchaseLoading,
    error: purchaseError,
    isSuccess: isPurchaseSuccess,
    reset,
  } = usePurchase()
  const isTablet = useMediaQuery("tablet")
  const isMobile = useMediaQuery("mobile")

  const {
    headerRef,
    contentRef,
    headerStyles,
    contentStyles,
    calculateStyles,
    resetStyles,
  } = useLayoutStyles()

  useEffect(() => {
    resetStyles()
    onMinimize()
  }, [isTablet, isMobile, isAvailable, initialTime, resetStyles])

  const onMinimize = () => {
    setMinimizedMobileHeader(true)
  }

  const onMaximize = () => {
    calculateStyles()
    setMinimizedMobileHeader(false)
  }

  const onAnimationLoadingEnd = (isLoading: boolean) => {
    setAvailabilityLoading(isLoading)
    openModal()
  }

  const onCheckAvailability = () => {
    setAvailabilityLoading(true)
    setTimeout(() => {
      setMinimizedMobileHeader(true)
      resetStyles()
      setIsAvailable(true)
      setInitialTime(EXPIRATION_TIME)
    }, 400)
  }

  const isPurchased = isPurchaseLoading || !!purchaseError || isPurchaseSuccess
  const [activeTab, setActiveTab] = useState<NavigationTab | null>(null)
  // animation conflict
  useEffect(() => {
    setTimeout(() => setActiveTab(NavigationTab.SUCCESS), 1)
  }, [])

  return (
    <>
      <div
        className={cx(styles.root, {
          [styles.loading]: isPurchaseLoading,
          [styles.error]: !!purchaseError,
          [styles.success]: isPurchaseSuccess,
          [styles.available]: availabilityLoading,
        })}>
        <InitialLoading className={styles.initialLoading} />

        {/* Availability State */}
        <AvailabilitySuccess
          isStarted={isSuccessModalOpen}
          onFinish={closeModal}
        />
        <AvailabilityLoading
          loading={availabilityLoading}
          onAnimationEnd={() => onAnimationLoadingEnd(false)}
        />
        {/* Availability State */}

        <div className={cx(styles.content)}>
          <>
            {isPurchased ? (
              <>
                {/* Payment State */}
                <PaymentLoading isShown={isPurchaseLoading} />
                <PaymentError error={purchaseError} onRetry={reset} />
                <PaymentSuccess isShown={isPurchaseSuccess} />
                {/* Payment State */}
              </>
            ) : (
              <>
                <SideWrapper
                  className={styles.tabsSide}
                  isTablet={isTablet || isMobile}
                  ref={contentRef}
                  style={contentStyles}>
                  <TabbedSection
                    activeTab={activeTab}
                    onTabChange={setActiveTab}
                  />
                </SideWrapper>
                <SideWrapper
                  className={styles.formSide}
                  isTablet={isTablet || isMobile}
                  ref={headerRef}
                  style={headerStyles}>
                  <div className={cx(styles.formContainer)}>
                    {isAvailable && !availabilityLoading ? (
                      <PaymentForm
                        className={styles.form}
                        initialTime={initialTime}
                        isPurchaseSuccess={isPurchaseSuccess}
                        minimizedMobileHeader={minimizedMobileHeader}
                        onAvailable={() => setIsAvailable(false)}
                        onMaximize={onMaximize}
                        onMinimize={onMinimize}
                      />
                    ) : (
                      <AvailabilityForm
                        className={styles.form}
                        minimizedMobileHeader={minimizedMobileHeader}
                        onCheckAvailability={onCheckAvailability}
                        onMaximize={onMaximize}
                        onMinimize={onMinimize}
                      />
                    )}
                  </div>
                </SideWrapper>
              </>
            )}
          </>
        </div>
      </div>
    </>
  )
}

interface SideWrapperProps {
  children: ReactNode
  className: string
  style: CSSProperties
  isTablet: boolean
}

const SideWrapper = forwardRef<HTMLDivElement, SideWrapperProps>(
  ({ children, style, isTablet, className }, ref) => {
    if (!isTablet) {
      return <div className={className}>{children}</div>
    }

    return (
      <div className={className} ref={ref} style={style}>
        {children}
      </div>
    )
  },
)
