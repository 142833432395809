export const safeJsonParse = <T>(value: string | null): T | null => {
  if (!value) {
    return null
  }

  try {
    return JSON.parse(value)
  } catch (e) {
    return null
  }
}
