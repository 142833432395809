import { video } from "assets/video"
import { NavigationTab } from "types/tabs"

export const tabsList: Record<
  NavigationTab,
  {
    title: string
    video: string
  }
> = {
  [NavigationTab.SUCCESS]: {
    title: "success",
    video: video.tabSuccess,
  },
  [NavigationTab.SITELIST]: {
    title: "sitelist",
    video: video.tabSiteList,
  },
  [NavigationTab.REGIONS]: {
    title: "regions",
    video: video.tabRegions,
  },
}
