import PRODUCT_CARD_1 from "./cards/card_1.svg"
import PRODUCT_CARD_10 from "./cards/card_10.svg"
import PRODUCT_CARD_11 from "./cards/card_11.svg"
import PRODUCT_CARD_12 from "./cards/card_12.svg"
import PRODUCT_CARD_13 from "./cards/card_13.svg"
import PRODUCT_CARD_14 from "./cards/card_14.svg"
import PRODUCT_CARD_15 from "./cards/card_15.svg"
import PRODUCT_CARD_16 from "./cards/card_16.svg"
import PRODUCT_CARD_17 from "./cards/card_17.svg"
import PRODUCT_CARD_18 from "./cards/card_18.svg"
import PRODUCT_CARD_19 from "./cards/card_19.svg"
import PRODUCT_CARD_2 from "./cards/card_2.svg"
import PRODUCT_CARD_20 from "./cards/card_20.svg"
import PRODUCT_CARD_21 from "./cards/card_21.svg"
import PRODUCT_CARD_22 from "./cards/card_22.svg"
import PRODUCT_CARD_23 from "./cards/card_23.svg"
import PRODUCT_CARD_24 from "./cards/card_24.svg"
import PRODUCT_CARD_25 from "./cards/card_25.svg"
import PRODUCT_CARD_26 from "./cards/card_26.svg"
import PRODUCT_CARD_27 from "./cards/card_27.svg"
import PRODUCT_CARD_28 from "./cards/card_28.svg"
import PRODUCT_CARD_29 from "./cards/card_29.svg"
import PRODUCT_CARD_3 from "./cards/card_3.svg"
import PRODUCT_CARD_30 from "./cards/card_30.svg"
import PRODUCT_CARD_31 from "./cards/card_31.svg"
import PRODUCT_CARD_32 from "./cards/card_32.svg"
import PRODUCT_CARD_33 from "./cards/card_33.svg"
import PRODUCT_CARD_34 from "./cards/card_34.svg"
import PRODUCT_CARD_35 from "./cards/card_35.svg"
import PRODUCT_CARD_36 from "./cards/card_36.svg"
import PRODUCT_CARD_4 from "./cards/card_4.svg"
import PRODUCT_CARD_5 from "./cards/card_5.svg"
import PRODUCT_CARD_6 from "./cards/card_6.svg"
import PRODUCT_CARD_7 from "./cards/card_7.svg"
import PRODUCT_CARD_8 from "./cards/card_8.svg"
import PRODUCT_CARD_9 from "./cards/card_9.svg"
import PRODUCT_CARD_BLUE from "./cards/product-card-blue.svg"
import PRODUCT_CARD_GREEN from "./cards/product-card-green.svg"
import PRODUCT_CARD_ORANGE from "./cards/product-card-orange.svg"
import MODAL_BACKGROUND from "./modal/robot-head.png"
import PAYMENT_HEAD_BLURRED from "./payment/head_blurred.png"
import REGION_BAR_BG from "./regions/info_region_bg.png"
import SITE_ADIDAS from "./sites/adidas.svg"
import SITE_AMAZON from "./sites/amazon.svg"
import SITE_ASOS from "./sites/asos.svg"
import SITE_FOOTLOCKER from "./sites/footlocker_champs.svg"
import SITE_JD_FINISHLINE from "./sites/jd_finishline.svg"
import SITE_NIKE from "./sites/nike.svg"
import SITE_RAKUTEN from "./sites/rakuten.svg"
import SITE_SHOPIFY from "./sites/shopify.svg"
import SITE_TARGET from "./sites/target.svg"
import SITE_ZALANDO from "./sites/zalando.svg"
import INSTA_BG from "./socials/insta_bg.png"
import TAB_ELLIPSE_BRIGHTER from "./tabs/ellipse-brighter.svg"
import TAB_ELLIPSE from "./tabs/ellipse.svg"
import TAB_HUB from "./tabs/hub_dashboard.svg"

export const images = {
  // product
  productCardBlue: PRODUCT_CARD_BLUE,
  productCardGreen: PRODUCT_CARD_GREEN,
  productCardOrange: PRODUCT_CARD_ORANGE,

  // tab
  tabEllipse: TAB_ELLIPSE,
  tabEllipseBrighter: TAB_ELLIPSE_BRIGHTER,
  tabHub: TAB_HUB,
  // site
  siteAsos: SITE_ASOS,
  siteRakuten: SITE_RAKUTEN,
  siteAmazon: SITE_AMAZON,
  siteFinishLine: SITE_JD_FINISHLINE,
  siteFootlocker: SITE_FOOTLOCKER,
  siteNike: SITE_NIKE,
  siteShopify: SITE_SHOPIFY,
  siteTarget: SITE_TARGET,
  siteZalando: SITE_ZALANDO,
  siteAdidas: SITE_ADIDAS,
  // modal
  modalBackground: MODAL_BACKGROUND,
  // payment
  paymentHeadBlurred: PAYMENT_HEAD_BLURRED,
  // regions
  regionBarBg: REGION_BAR_BG,
  // socials
  instagramBg: INSTA_BG,
}

export const productCardsImages = {
  productCard1: PRODUCT_CARD_1,
  productCard2: PRODUCT_CARD_2,
  productCard3: PRODUCT_CARD_3,
  productCard4: PRODUCT_CARD_4,
  productCard5: PRODUCT_CARD_5,
  productCard6: PRODUCT_CARD_6,
  productCard7: PRODUCT_CARD_7,
  productCard8: PRODUCT_CARD_8,
  productCard9: PRODUCT_CARD_9,
  productCard10: PRODUCT_CARD_10,
  productCard11: PRODUCT_CARD_11,
  productCard12: PRODUCT_CARD_12,
  productCard13: PRODUCT_CARD_13,
  productCard14: PRODUCT_CARD_14,
  productCard15: PRODUCT_CARD_15,
  productCard16: PRODUCT_CARD_16,
  productCard17: PRODUCT_CARD_17,
  productCard18: PRODUCT_CARD_18,
  productCard19: PRODUCT_CARD_19,
  productCard20: PRODUCT_CARD_20,
  productCard21: PRODUCT_CARD_21,
  productCard22: PRODUCT_CARD_22,
  productCard23: PRODUCT_CARD_23,
  productCard24: PRODUCT_CARD_24,
  productCard25: PRODUCT_CARD_25,
  productCard26: PRODUCT_CARD_26,
  productCard27: PRODUCT_CARD_27,
  productCard28: PRODUCT_CARD_28,
  productCard29: PRODUCT_CARD_29,
  productCard30: PRODUCT_CARD_30,
  productCard31: PRODUCT_CARD_31,
  productCard32: PRODUCT_CARD_32,
  productCard33: PRODUCT_CARD_33,
  productCard34: PRODUCT_CARD_34,
  productCard35: PRODUCT_CARD_35,
  productCard36: PRODUCT_CARD_36,
}

export type ProductImageName = keyof typeof productCardsImages
