import { backgrounds } from "assets/background"
import { useMediaQuery } from "hooks"
import { useEffect, useRef, useState } from "react"
import GlobeBase, { GlobeMethods } from "react-globe.gl"
import { useWindowSize } from "react-use"
import { cx } from "styles"
import { Country, CountryPoint } from "types/tabs"

import styles from "./Globe.module.scss"
import {
  arcsGlobeData,
  globeSettings,
  pointFlagData,
  positionsFlagData,
} from "./data"
import { getTooltip } from "./utils"

interface GlobeProps {
  className?: string
}

export const Globe = ({ className }: GlobeProps) => {
  const globeEl = useRef<GlobeMethods>()
  const containerRef = useRef<HTMLDivElement>(null)
  const isTablet = useMediaQuery("tablet")
  const isMobile = useMediaQuery("mobile")
  const [globeWidth, setGlobeWidth] = useState(0)
  const [globeHeight, setGlobeHeight] = useState(0)

  const sizes = useWindowSize()

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth
      const containerHeight = containerRef.current.clientHeight

      setGlobeWidth(containerWidth)
      setGlobeHeight(containerHeight)
    }

    if (globeEl.current) {
      globeEl.current.controls().enableZoom = true
      globeEl.current.controls().autoRotate = true
      globeEl.current.controls().autoRotateSpeed = 0.7
    }
  }, [globeEl.current, containerRef.current, sizes])

  useEffect(() => {
    if (globeEl.current) {
      const globeInstance = globeEl.current
      const xOffset = 0
      const yOffset = 100
      const camera = globeInstance.camera()
      camera.position.x += xOffset
      camera.position.y += yOffset
      const xSceneOffset = 0
      const ySceneOffset = isMobile ? 0 : isTablet ? 10 : -3
      const scene = globeInstance.scene()
      scene.position.x += xSceneOffset
      scene.position.y += ySceneOffset
    }
  }, [isTablet, isMobile])

  return (
    <div className={cx(styles.root, className)} ref={containerRef}>
      {/* src/styles/theme.scss */}
      <div className={cx(styles.globe, "globe")}>
        <GlobeBase
          {...globeSettings}
          animateIn
          arcAltitudeAutoScale={0.5}
          arcDashAnimateTime={3000}
          arcDashGap={() => Math.random()}
          arcDashLength={1}
          arcsData={arcsGlobeData}
          backgroundImageUrl={backgrounds.globeEnvironment}
          globeImageUrl={backgrounds.globe}
          height={globeHeight}
          htmlAltitude={0.09}
          htmlElement={(d) => {
            const data = d as Country
            const flagWidth = "15px"

            if (data.customFlag) {
              const el = document.createElement("img")
              el.src = data.customFlag
              el.style.width = flagWidth
              el.style.cursor = "default"
              return el
            }

            const el = document.createElement("div")
            el.innerHTML = data.flag
            el.style.fontSize = flagWidth
            return el
          }}
          htmlElementsData={positionsFlagData}
          pointAltitude={0.09}
          pointLabel={(r) => getTooltip(r as CountryPoint)}
          pointsData={pointFlagData}
          ref={globeEl}
          showAtmosphere={false}
          width={globeWidth}
        />
      </div>
    </div>
  )
}
