import { ParallacticTiltCard } from "components"
import { useResize } from "hooks"
import { useRef, useState } from "react"
import { cx } from "styles"
import { CardColor } from "types/productCards"

import styles from "./ProductCard.module.scss"
import { cardStyles } from "./cardStyles"

interface ProductCardProps {
  className?: string
  type?: CardColor
  disabled?: boolean
  img: string
}

export const ProductCard = ({
  className,
  type = "blue",
  disabled,
  img,
}: ProductCardProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { boxShadow, background, glareColor } = cardStyles[type]
  const [imageLoaded, setImageLoaded] = useState(false)

  const handleImageLoad = () => {
    setImageLoaded(true)
  }
  const tiltProps = {
    // as in the "root" className
    glareBorderRadius: "1.9rem",
    glareMaxOpacity: !disabled ? 0.2 : 0,
    scale: 1,
    tiltMaxAngleX: 10,
    tiltMaxAngleY: 10,
    transitionSpeed: 7000,
    glareEnable: !disabled,
    glareColor,
  }

  const { width } = useResize(ref)

  return (
    <ParallacticTiltCard
      {...tiltProps}
      className={cx(styles.root, className)}
      style={{ boxShadow, background }}
      tiltEnable={!disabled}>
      <div
        className={styles.wrapper}
        ref={ref}
        style={{ height: width > 0 ? width : "auto" }}>
        <div
          className={cx(styles.loading, {
            [styles.hidden]: imageLoaded,
          })}
        />
        <img
          alt="product-card"
          className={styles.image}
          draggable={false}
          src={img}
          onLoad={handleImageLoad}
        />
      </div>
    </ParallacticTiltCard>
  )
}
