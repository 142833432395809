import { Ref, forwardRef } from "react"
import ConfettiBase, { Props as ConfettiPropsBase } from "react-confetti"

interface ConfettiProps extends ConfettiPropsBase {
  className?: string
}

export const Confetti = forwardRef(
  (
    { className, ...confettiProps }: ConfettiProps,
    ref: Ref<HTMLCanvasElement>,
  ) => <ConfettiBase className={className} ref={ref} {...confettiProps} />,
)
