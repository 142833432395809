import { motion } from "framer-motion"
import { useState } from "react"
import { useSwipeable } from "react-swipeable"
import { cx } from "styles"
import { isTouchableDevice } from "utils/getDeviceType"

import styles from "./FeaturesMobile.module.scss"

export interface FeatureType {
  features: string[]
  title: string
}

interface FeaturesMobileProps {
  className?: string
  items: FeatureType[] | null
  headerIcons: string[]
  additionalListId: number | null
  setAdditionalListId: (id: number | null) => void
}

export const FeaturesMobile = ({
  className,
  items,
  headerIcons,
  additionalListId,
  setAdditionalListId,
}: FeaturesMobileProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const onSwipe = (direction: "right" | "left") => {
    if (items) {
      setCurrentIndex((prevIndex) => {
        if (direction === "left") {
          return prevIndex < items.length - 1 ? prevIndex + 1 : 0
        }

        return prevIndex > 0 ? prevIndex - 1 : items.length - 1
      })
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => onSwipe("left"),
    onSwipedRight: () => onSwipe("right"),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  })

  const item = items ? items[currentIndex] : null

  const isAdditionalListOpen =
    additionalListId === currentIndex && !!item?.features.length

  const isFirstSlide = currentIndex === 0
  const isLastSlide = !!items && currentIndex === items.length - 1

  const onColumnChange = () =>
    setAdditionalListId(currentIndex === additionalListId ? null : currentIndex)

  return (
    <div
      className={cx(styles.root, className, {
        [styles.hidden]: !items,
      })}>
      <div className={styles.content}>
        <motion.div
          className={styles.featuresContainer}
          // click outside does not work when this component is clicked
          onClick={(e) => e.stopPropagation()}>
          {!!item && (
            <>
              <Arrow
                currentIndex={currentIndex}
                disabled={isFirstSlide}
                setCurrentIndex={setCurrentIndex}
                type="left"
              />
              <motion.div
                {...handlers}
                animate={{
                  opacity: currentIndex === currentIndex ? 1 : 0,
                  x: currentIndex === currentIndex ? 0 : "-100%",
                }}
                className={cx(styles.feature, {
                  [styles.hiddenList]: isAdditionalListOpen,
                })}
                exit={{ opacity: 0, x: "100%" }}
                initial={{ opacity: 0, x: "0%" }}
                key={currentIndex}
                transition={{ duration: 0.5 }}
                onClick={() => {
                  if (isTouchableDevice()) {
                    onColumnChange()
                  }
                }}
                onMouseEnter={() => {
                  if (!isTouchableDevice()) {
                    onColumnChange()
                  }
                }}
                onMouseLeave={() => {
                  setAdditionalListId(null)
                }}>
                <div className={styles.left}>
                  <div className={styles.iconArea}>
                    <div className={styles.iconContainer}>
                      <img
                        alt={item.title}
                        className={styles.icon}
                        draggable={false}
                        src={headerIcons[currentIndex]}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.right}>
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.info}>
                    {item.features.slice(0, 2).join("\n")}
                  </div>
                </div>
                <div
                  className={cx(styles.additionalListContainer, {
                    [styles.additionalListActive]: isAdditionalListOpen,
                  })}>
                  <div className={styles.additionalListText}>
                    {item.features.join("\n")}
                  </div>
                </div>
              </motion.div>

              <Arrow
                currentIndex={currentIndex}
                disabled={isLastSlide}
                setCurrentIndex={setCurrentIndex}
                type="right"
              />
            </>
          )}
        </motion.div>
      </div>
      {/* click outside does not work when this component is clicked */}
      <div className={styles.dots} onClick={(e) => e.stopPropagation()}>
        {items ? (
          items.map((_, index) => (
            <button
              className={cx(styles.dot, {
                [styles.activeDot]: currentIndex === index,
              })}
              key={index}
              onClick={() => setCurrentIndex(index)}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

interface ArrowProps {
  setCurrentIndex: (val: number) => void
  disabled: boolean
  currentIndex: number
  type: "left" | "right"
}

export const Arrow = ({
  disabled,
  setCurrentIndex,
  currentIndex,
  type,
}: ArrowProps) => {
  const arrowStyles = {
    left: styles.leftArrow,
    right: styles.rightArrow,
  }

  const index = {
    left: currentIndex - 1,
    right: currentIndex + 1,
  }

  return (
    <button
      className={cx(styles.arrow, {
        [styles.disabledArrow]: disabled,
      })}
      onClick={(e) => {
        if (disabled) {
          e.stopPropagation()
        }
        if (!disabled) {
          setCurrentIndex(index[type])
        }
      }}>
      <div className={cx(styles.innerArrow, arrowStyles[type])} />
    </button>
  )
}
