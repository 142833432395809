import { errorName } from "api/constants"
import { delay, makeApiError } from "api/utils"
import { envConfig } from "config"
import { PurchaseInfo } from "types/purchase"

import { request } from "../requestCore"

export const getTest = async () => {
  console.log("PROD")
  const result = await request.get<{ id: number; name: string }[]>(
    `${envConfig.BASE_URL}/test`,
  )

  return result.data
}

export const purchaseSubscription = async ({
  license_key: licenseKey,
  amount,
  subscription_duration,
  payment_info,
}: PurchaseInfo) => {
  // display payment loading
  await delay(3000)

  const res = await request.post(`${envConfig.BASE_URL}/renewal/payExpired`, {
    license_key: licenseKey,
    subscription_duration,
    payment_info: {
      token: payment_info.token,
      contact_email: payment_info.contact_email,
    },
  })
  if (res.data.success.toString() !== "true") {
    throw makeApiError(res.data.error || errorName.unknown, "payment failed")
  }
}

export const getRemainingCopies = async () => {
  const result = await request.get<{ available: number }>(
    `${envConfig.BASE_URL}/renewal/available`,
  )

  return result.data
}
