import { ProductImageName, productCardsImages } from "assets/images"
import { memo } from "react"
import { cx } from "styles"
import { CardColor } from "types/productCards"

import { ProductCard } from "../../components/ProductCard"

import styles from "./ScrollableProductCards.module.scss"

interface ScrollableProductCardsProps {
  className?: string
  cardColors: CardColor[][]
  hidden?: boolean
  cardsIds: number[][]
}

export const ScrollableProductCards = memo(
  ({
    className,
    cardColors,
    hidden,
    cardsIds,
  }: ScrollableProductCardsProps) => {
    return (
      <div className={cx(styles.root, className)}>
        {cardsIds.map((cardsId, index) => {
          const isEven = index % 2 === 0

          return (
            <div className={styles.container} key={index}>
              <div
                className={cx(styles.scroll, {
                  [styles.evenColumn]: isEven,
                })}>
                {cardsId.map((id, idx) => {
                  const image = `productCard${id}` as ProductImageName
                  return (
                    <ProductCard
                      className={cx(styles.card)}
                      disabled={hidden}
                      img={productCardsImages[image]}
                      key={idx}
                      type={cardColors[index][idx]}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  },
)
