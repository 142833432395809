import axios, { AxiosResponse, AxiosError } from "axios"

export const BASE_URL = "/"

const onSuccess = (response: AxiosResponse) => response

const useError = async (error: AxiosError) => {
  return Promise.reject(error)
}

export const request = axios.create({})

request.interceptors.response.use(onSuccess, useError)

export { AxiosError } from "axios"
