import { icons } from "assets/icons"
import { TiltCard, commonTiltProps } from "components"
import { cx } from "styles"

import styles from "./PaymentSuccess.module.scss"

const tiltCardSettings = {
  ...commonTiltProps,
  glareMaxOpacity: 0.2,
  glareColor: "rgba(145, 106, 255, 0.5)",
}

interface PaymentSuccessProps {
  isShown: boolean
}

export const PaymentSuccess = ({ isShown }: PaymentSuccessProps) => {
  return (
    <div className={cx(styles.root, { [styles.shown]: isShown })}>
      <div className={styles.content}>
        <img
          alt="congratulations"
          className={styles.title}
          draggable={false}
          src={icons.congratulations}
        />
        <div className={styles.card}>
          <TiltCard
            className={styles.cardContainer}
            settings={tiltCardSettings}>
            <div className={styles.cardContent}>
              <div className={styles.cardInfo}>
                <div className={styles.cardTitle}>1x License Key</div>
                <div className={styles.cardSubtitle}>KODAI</div>
              </div>
            </div>
          </TiltCard>
        </div>
        <div className={styles.caption}>
          Welcome to Kodai! You will receive an email with
          <br />
          your onboarding instructions soon.
        </div>
      </div>
    </div>
  )
}
