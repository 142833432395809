import { useMutation } from "@tanstack/react-query"

import { purchaseSubscription } from "../core"

export const usePurchaseSubscription = () => {
  return useMutation({
    mutationKey: ["purchase-subscription"],
    mutationFn: purchaseSubscription,
  })
}
