import { ReactParallaxTiltProps } from "react-parallax-tilt"

export const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`
}

export const animateVariants = {
  in: {
    height: "initial",
    opacity: 1,
  },
  out: {
    height: 0,
    opacity: 0,
  },
  transition: {
    duration: 0.4,
  },
  initial: false,
}

export const tiltSettings: ReactParallaxTiltProps = {
  glareEnable: true,
  glarePosition: "all",
  glareMaxOpacity: 0,
  scale: 1.1,
  tiltMaxAngleX: 20,
  tiltMaxAngleY: 20,
  transitionSpeed: 3000,
}
